import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Edit, Image } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchhomePages, updateHomeBannerImage, updateHomeAboutUsImage, updateHomeFaqImage, updateHomeAddImage } from '../../store/homepageSlice';



const Blogs = () => {

  const dispatch = useDispatch();
  const storeVar = useSelector(state => state.homepage)
  const [submit, setSubmit] = useState(false);
  const [submitFaqStatus, setSubmitFaqStatus] = useState(false);
  const [submitAbout, setSubmitAbout] = useState(false);
  const [submitAddStatus, setSubmitAdd] = useState(false);
  const [formVar, setFormVar] = useState({
    bannerFile: null,
    bannerImageURL: null,
    aboutUsFile: null,
    aboutUsImageURL: null,
    faqsFile: null,
    faqsImageURL: null,
    addFile: null,
    addImageURL: null,
  });

  useEffect(() => {
    dispatch(fetchhomePages());
  }, []);




  const bannerImageValid = () => {
    if (!formVar.bannerFile) {
      return "Banner Image is required";
    }
  }
  const aboutUsImageValid = () => {
    if (!formVar.aboutUsFile) {
      return "About Us Image is required";
    }
  }
  const faqImageValid = () => {
    if (!formVar.faqsFile) {
      return "FAQs Image is required";
    }
  }
  const AddImageValid = () => {
    if (!formVar.addFile) {
      return "Add Image is required";
    }
  }
  const submitBanner = () => {
    if (bannerImageValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    dispatch(updateHomeBannerImage(formVar.bannerFile))
  }
  const submitAboutUs = () => {
    if (aboutUsImageValid()) {
      setSubmitAbout(true)
      return null
    }
    setSubmitAbout(false)

    dispatch(updateHomeAboutUsImage(formVar.aboutUsFile))
  }
  const submitFaq = () => {
    if (faqImageValid()) {
      setSubmitFaqStatus(true)
      return null
    }
    setSubmitFaqStatus(false)
    dispatch(updateHomeFaqImage(formVar.faqsFile))
  }
  const submitAdd = () => {
    if (AddImageValid()) {
      setSubmitAdd(true)
      return null
    }
    setSubmitAdd(false)

    dispatch(updateHomeAddImage(formVar.addFile))
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          bannerImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: null,
        bannerImageURL: null,
      }))
    }
  };
  const handleAboutUsChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          aboutUsImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        aboutUsFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        aboutUsFile: null,
        aboutUsImageURL: null,
      }))
    }
  };
  const handleFaqChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          faqsImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        faqsFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        faqsFile: null,
        faqsImageURL: null,
      }))
    }
  };
  const handleAddChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          addImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        addFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        addFile: null,
        addImageURL: null,
      }))
    }
  };
  


  return (
    <Col sm='12'>
      <Card>
        <CardHeader>
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Banner Video(Use .mp4 Video)</Label>
          <Row>
            <Col md="4">
              {storeVar.homePageData?.mainBanner ? <img className='w-80 h-5-r' src={storeVar.homePageData?.mainBanner} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                accept="video/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              />
              </Col>

            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitBanner }}>Save Changes</Btn>
            </Col>
          </Row>
          {submit && bannerImageValid() ? <span className='d-block font-danger'>{bannerImageValid()}</span> : ""}
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">About Us Image (Recomended Size is 515px X 515px)</Label>
          <Row>
            <Col md="4">
              <FormGroup>
                {storeVar.homePageData?.aboutImg ? <img className='w-80 h-5-r' src={storeVar.homePageData?.aboutImg} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
              </FormGroup>
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleAboutUsChangeStatus}
                maxFiles={1}
                multiple={false}
                accept="image/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>
            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitAboutUs }}>Save Changes</Btn>

            </Col>
          </Row>
          {submitAbout && aboutUsImageValid() ? <span className='d-block font-danger'>{aboutUsImageValid()}</span> : ""}

          <Label className="col-form-label fw-semibold f-20" for="recipient-name">FAQs Image (Recomended Size is 515px X 515px)</Label>
          <Row>
            <Col md="4">
              <FormGroup>
                {storeVar.homePageData?.faqImg ? <img className='w-80 h-5-r' src={storeVar.homePageData?.faqImg} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
              </FormGroup>
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleFaqChangeStatus}
                maxFiles={1}
                multiple={false}
                accept="image/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>
            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitFaq }}>Save Changes</Btn>
            </Col>
          </Row>
          {submitFaqStatus && faqImageValid() ? <span className='d-block font-danger'>{faqImageValid()}</span> : ""}
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Adds Video (Use .mp4 Video)</Label>
          <Row>
            <Col md="4">
              <FormGroup>
                {storeVar.homePageData?.adImg ? <img className='w-80 h-5-r' src={storeVar.homePageData?.adImg} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
              </FormGroup>
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleAddChangeStatus}
                maxFiles={1}
                multiple={false}
                accept="video/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>
            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitAdd }}>Save Changes</Btn>
            </Col>
          </Row>
          {submitAddStatus && AddImageValid() ? <span className='d-block font-danger'>{AddImageValid()}</span> : ""}

        </CardHeader>
      </Card>
    </Col>
  );
};

export default Blogs;
