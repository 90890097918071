import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import Test from './Test';
const Level = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Tests' mainTitle='Tests' />
      <Container fluid={true}>
        <Row>
          <Test/>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Level;
