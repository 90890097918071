import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { updatewhyUs } from '../../store/whyUsSlice';


const HomePageModel = ({ props }) => {
    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [formVar, setFormVar] = useState({
        heading1: '',
        author: '',
        heading2: '',
        bgHeading: '',
        desc: '',
        pkTitle: '',
    });
    useEffect(() => {
        if (props) {
            setFormVar({
                heading1: props?.heading1 || '',
                author: props?.author || '',
                heading2: props?.heading2 || '',
                bgHeading: props?.bgHeading || '',
                desc: props?.desc || '',
                pkTitle: props?.pkTitle || '',
            });
        }
    }, [props]);

    const submitNewSettings = () => {

        if (heading1Valid()) {
            setSubmit(true)
            return null
        }
        if (authorValid()) {
            setSubmit(true)
            return null
        }
        if (heading2Valid()) {
            setSubmit(true)
            return null
        }
        if (bgHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (descriptionValid()) {
            setSubmit(true)
            return null
        }
        if (perksValid()) {
            setSubmit(true)
            return null
        }

        setSubmit(false)
        dispatch(updatewhyUs(formVar));
    }
    const heading1Valid = () => {
        if (!formVar.heading1) {
            return "Heading 1 is required";
        }
    }
    const authorValid = () => {
        if (!formVar.author) {
            return "Author Name is required";
        }
    }
    const heading2Valid = () => {
        if (!formVar.heading2) {
            return "Heading 2 is required";
        }
    }
    const bgHeadingValid = () => {
        if (!formVar.bgHeading) {
            return "Background Heading is required";
        }
    }
    const descriptionValid = () => {
        if (!formVar.desc) {
            return "Description is required";
        }
    }
    const perksValid = () => {
        if (!formVar.pkTitle) {
            return "Perks Title is required";
        }
    }

    return (
        <>
            <Col sm='12'>
                <Card>
                    <CardHeader>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Heading  </Label>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Heading 1</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Heading 1 ' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, heading1: e.target.value }))} value={formVar?.heading1} />
                                    {submit && heading1Valid() ? <span className='d-block font-danger'>{heading1Valid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Author</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Author Name ' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, author: e.target.value }))} value={formVar?.author} />
                                    {submit && authorValid() ? <span className='d-block font-danger'>{authorValid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Heading 2</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Heading 2 ' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, heading2: e.target.value }))} value={formVar?.heading2} />
                                    {submit && heading2Valid() ? <span className='d-block font-danger'>{heading2Valid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Background Heading</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Background Heading' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, bgHeading: e.target.value }))} value={formVar?.bgHeading} />
                                    {submit && bgHeadingValid() ? <span className='d-block font-danger'>{bgHeadingValid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label className="col-form-label fw-semibold f-20" for="recipient-name">Description  </Label>

                            <Col md="">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Description</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
                                    {submit && descriptionValid() ? <span className='d-block font-danger'>{descriptionValid()}</span> : ""}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Perks</Label>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Perks Heading</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Perks Heading' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, pkTitle: e.target.value }))} value={formVar?.pkTitle} />
                                    {submit && perksValid() ? <span className='d-block font-danger'>{perksValid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <ModalFooter style={{ justifyContent: "center" }}>
                            <Btn attrBtn={{ color: 'primary', onClick: submitNewSettings }}>Save </Btn>
                        </ModalFooter>


                    </CardHeader>
                </Card>
            </Col >
        </>
    );
};

export default HomePageModel;
