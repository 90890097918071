
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isOpenAsignModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setlead(state, { payload }) { 
      state.leadData = payload.result;
      state.count = payload.count;
    },
    updateleadsData(state, { payload }) {
      const objIndex = state.leadData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.leadData[objIndex] = payload
      }
    },
    pushleadsData(state, { payload }) {
      state.leadData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    ModalAsignToggle(state, { payload }) {
      state.isOpenAsignModal = !state.isOpenAsignModal
    },
    isOpenAsignModal(state, { payload }) {
      state.isOpenAsignModal = payload
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setlead, updateleadsData, pushleadsData, DeleteleadsData, isOpenModal,ModalAsignToggle,isOpenAsignModal, isImageOpenModal, ModalToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle } = leadsSlice.actions;
export default leadsSlice.reducer;

export function fetchlead(limit, keyword, offset, status, startDate, endDate) {
  return async function fetchleadThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.leadData(limit, keyword, offset, status, startDate, endDate).then(
        (response) => {
          dispatch(setlead(response.data));
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
        }
      );

    } catch (err) {

    }
  }
}
export function addlead(payload) {
  return async function addleadsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.createleads(payload).then(
        (response) => {
          
          dispatch(pushleadsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function asignStaff(payload) {
  return async function asignStaffThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.asignStaff(payload).then(
        (response) => {
          // dispatch(updateleadsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalAsignToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatelead(payload) {

  return async function updateleadsThunk(dispatch) {
    
    try {
      dispatch(setLoading(true))
      await service.updateLeads(payload.id, payload.fullName, payload.email, payload.phoneNumber, payload.altPhoneNumber, payload.enquiryFor, payload.desc).then(
        (response) => {
          
          dispatch(updateleadsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageleads(id, file) {

  return async function updateleadsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateleadsImage(id, file).then(
        (response) => {
          
          dispatch(updateleadsData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateLeadStatus(payload) {
  return async function statusUpdateleadsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateleads(payload.id, payload.status).then(
        (response) => {
          
          dispatch(updateleadsData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeleteleadstatus(id, status) {
  return async function statusDeleteleadsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateleads(id, status).then(
        (response) => {
          
          dispatch(updateleadsData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

