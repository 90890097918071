import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import FaqsTable from './PerksTable';
const Faqs = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Perks' mainTitle='Perks' />
      <Container fluid={true}>
        <Row>
          <FaqsTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Faqs;
