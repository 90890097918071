import React, { Fragment, useEffect, useState,  } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, Trash2 } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { getFaqs } from '../../store/faqsSlice';
import { getStaff, addStaff, updateStaff, deleteStaff, isOpenModal, ModalToggle } from '../../store/staffSlice';
import Pagination from '../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';

const StaffTable = () => {
  const storeVar = useSelector(state => state.staff)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    status: 'ACTIVE',
    roles: 'STAFF',
    keyword: '',

    currentPage:1,
    modalTitle: null,
    editState: false,

    id: '',
    staffName: '',
    email: '',
    loginId: '',
    staffPassword: '',
    staffStatus: 'ACTIVE',

    faqsId: null,
    faqStatus: 'ACTIVE',
    question: '',
    answer: '',
    tag: '',
    pageId: '8'
  });

  useEffect(() => {
    dispatch(getStaff(formVar.limit, formVar.offset, formVar.status, formVar.roles, formVar.keyword))
  }, []);


  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    dispatch(getStaff(formVar.limit, formVar.offset, formVar.status, formVar.roles, e.target.value))
  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getFaqs(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getStaff(formVar.limit, formVar.offset, e.target.value, formVar.roles, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      modalTitle: 'Edit Staff',

      id: data.id,
      staffName: data.staffName,
      email: data.email,
      loginId: data.phoneNumber,
      staffPassword: data.staffPassword,
      staffStatus: data.status,
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'New Staff',
      staffName: '',
      email: '',
      loginId: '',
      staffPassword: '',
      staffStatus: 'PENDING',
    }))
  }

  const submitDegree = () => {
    if (formVar.editState) {
      if (staffNameValid() || emailValid() || loginIdValid() || staffPasswordValid() || staffStatusValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(updateStaff({ id: formVar.id, loginId: formVar.loginId, staffPassword: formVar.staffPassword, staffName: formVar.staffName, email: formVar.email, status: formVar.staffStatus }))
    } else {
      if (staffNameValid() || emailValid() || loginIdValid() || staffPasswordValid() || staffStatusValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(addStaff({ loginId: formVar.loginId, staffPassword: formVar.staffPassword, staffName: formVar.staffName, email: formVar.email, roles: formVar.roles }))
    }
  }

  const StaffDelete = (id) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this staff!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deleteStaff(id))
        }
      });
  }

  const staffNameValid = () => {
    if (!formVar.staffName) {
      return "Staff name is required";
    }
  }
  const emailValid = () => {
    if (!formVar.email) {
      return "Email name is required";
    }
  }
  const loginIdValid = () => {
    if (!formVar.loginId) {
      return "Login Id is required";
    }
  }
  const staffPasswordValid = () => {
    if (!formVar.staffPassword) {
      return "Password Id is required";
    }
    else if (formVar.staffPassword.length < 8) {
      return "Please Enter Password of Atleast 8 Words";
    }
  }
  const staffStatusValid = () => {
    if (!formVar.staffStatus) {
      return "Status is required";
    }
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
              
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add New Staff
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Login ID</th>
                  <th scope='col'>Total Leads</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.staffData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.staffName} </td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td></td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => StaffDelete(item.id)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar.staffData.length>0 &&
        <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalStaff}
          itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>



      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Staff Name</Label>
                <Input className="form-control" type="text" placeholder='Enter Fullname' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, staffName: e.target.value }))} value={formVar.staffName} />
                {submit && staffNameValid() ? <span className='d-block font-danger'>{staffNameValid()}</span> : ""}
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Email</Label>
                <Input className="form-control" type="email" placeholder='Enter Email' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, email: e.target.value }))} value={formVar.email} />
                {submit && emailValid() ? <span className='d-block font-danger'>{emailValid()}</span> : ""}
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Login ID</Label>
                <Input className="form-control" type="text" placeholder='Create a Unique Login ID' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, loginId: e.target.value }))} value={formVar.loginId} />
                {submit && loginIdValid() ? <span className='d-block font-danger'>{loginIdValid()}</span> : ""}
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Password</Label>
                <Input className="form-control" type="text" placeholder='Create a Password' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, staffPassword: e.target.value }))} value={formVar.staffPassword} />
                {submit && staffPasswordValid() ? <span className='d-block font-danger'>{staffPasswordValid()}</span> : ""}
              </Col>
            </Row>

            {formVar.editState && 
              <Row>
                <Col md="6">
                  <Label className="col-form-label" for="recipient-name">Status</Label>
                  <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                    value={formVar.staffStatus} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, staffStatus: e.target.value }))}>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DEACTIVE'>DEACTIVE</option>
                    <option value='PENDING'>PENDING</option>
                  </Input>
                  {submit && staffStatusValid() ? <span className='d-block font-danger'>{staffStatusValid()}</span> : ""}
                </Col>
              </Row>
            }
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default StaffTable;
