
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setcourse(state, { payload }) {
        state.courseData = payload.result;
        state.coursesCount = payload.total;
      },
    setbrand(state, { payload }) {
      state.brandData = payload;
    },
    pushCourse(state, { payload }) {
      state.courseData.push(payload)
    },
    updatecourseData(state, { payload }) {
      const objIndex = state.courseData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.courseData[objIndex] = payload
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setcourse, setbrand, pushCourse, updatecourseData, DeleteBrandsData, isOpenModal, isImageOpenModal, ModalToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle } = courseSlice.actions;
export default courseSlice.reducer;

export function fetchcourse(limit, offset, status, keyword) {
  return async function fetchcourseThunk(dispatch, getState) {
    try {
      await service.getcourse(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setcourse(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}
export function addcourse(name) {
  return async function addcourseThunk(dispatch) {
    try { 
      dispatch(setLoading(true))
      await service.createcourse(name).then(
        (response) => {
          dispatch(pushCourse(response.data))
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateCourse(id, payload) {
  return async function updatecourseThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecoursedata(id, payload).then(
        (response) => {
          dispatch(updatecourseData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateCourseStatus(payload) {
  return async function statusUpdateBlogsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCourse(payload.id, payload.status).then(
        (response) => {
          dispatch(updatecourseData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeleteCourseStatus(id, status) {
  return async function statusDeleteBrandsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCourse(id, status).then(
        (response) => {
          dispatch(updatecourseData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageCourse(id, file) {
  return async function updatecourseThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecourseImage(id, file).then(
        (response) => {
          dispatch(updatecourseData(response.data))
          dispatch(setLoading(false))
          dispatch(ImagestatusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
