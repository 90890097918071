import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomePageModel from '../../Components/Home/HomePageModel';
import { fetchhomePages} from '../../store/homepageSlice';


const HomePage = () => {
  const storeVar = useSelector(state => state.homepage)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchhomePages());
  }, []);

  return (
    <Fragment>
      <HomePageModel props={storeVar?.homePageData} />
    </Fragment>
  );
};

export default HomePage;
