import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Edit, Image } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchhomePages} from '../../store/homepageSlice';
import { fetchaboutUs, updateCouncelor1Image, updateCouncelor2Image, updateAboutAddImage } from '../../store/aboutUsSlice';



const Blogs = () => {

  const dispatch = useDispatch();
  // const storeVar = useSelector(state => state.homepage)
  const storeVar = useSelector(state => state.aboutUs)
  const [submit, setSubmit] = useState(false);
  const [submitFaqStatus, setSubmitFaqStatus] = useState(false);
  const [submitAbout, setSubmitAbout] = useState(false);
  const [submitAddStatus, setSubmitAdd] = useState(false);
  const [formVar, setFormVar] = useState({
    councelor1File: null,
    councelor1ImageURL: null,
    councelor2File: null,
    councelor2ImageURL: null,
    addImageFile: null,
    addImageURL: null,
  });

  useEffect(() => {
    dispatch(fetchhomePages());
    dispatch(fetchaboutUs());
  }, []);




  const councelor1ImageValid = () => {
    if (!formVar.councelor1File) {
      return "Councelor 1 Image is required";
    }
  }
  const aboutUsImageValid = () => {
    if (!formVar.councelor2File) {
      return "Councelor 2 Image is required";
    }
  }
  const AddImageValid = () => {
    if (!formVar.addImageFile) {
      return "Add Image is required";
    }
  }
  const submitCouncelor1 = () => {
    if (councelor1ImageValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    dispatch(updateCouncelor1Image(formVar.councelor1File))
  }
  const submitCouncelor2 = () => {
    if (aboutUsImageValid()) {
      setSubmitAbout(true)
      return null
    }
    setSubmitAbout(false)

    dispatch(updateCouncelor2Image(formVar.councelor2File))
  }
  const submitAddvertise = () => {
    if (AddImageValid()) {
      setSubmitAdd(true)
      return null
    }
    setSubmitFaqStatus(false)

    dispatch(updateAboutAddImage(formVar.addImageFile))

  }


  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          councelor1ImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        councelor1File: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        councelor1File: null,
        councelor1ImageURL: null,
      }))
    }
  };
  const handleCouncelor2Status = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          councelor2ImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        councelor2File: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        councelor2File: null,
        councelor2ImageURL: null,
      }))
    }
  };
  const handleAddImageStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          addImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        addImageFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        addImageFile: null,
        addImageURL: null,
      }))
    }
  };



  return (
    <Col sm='12'>
      <Card>
        <CardHeader>
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Councelor 1 Image (Recomended Size is 515px X 515px)</Label>
          <Row>
            <Col md="4">
              {storeVar.aboutUsData?.img1 ? <img className='w-80 h-5-r' src={storeVar.aboutUsData?.img1} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                accept="image/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>

            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitCouncelor1 }}>Save Changes</Btn>
            </Col>
          </Row>
          {submit && councelor1ImageValid() ? <span className='d-block font-danger'>{councelor1ImageValid()}</span> : ""}
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Councelor 2 Image (Recomended Size is 515px X 515px)</Label>
          <Row>
            <Col md="4">
              <FormGroup>
                {storeVar.aboutUsData?.img2 ? <img className='w-80 h-5-r' src={storeVar.aboutUsData?.img2} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
              </FormGroup>
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleCouncelor2Status}
                maxFiles={1}
                multiple={false}
                accept="image/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>
            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitCouncelor2 }}>Save Changes</Btn>

            </Col>
          </Row>
          {submitAbout && aboutUsImageValid() ? <span className='d-block font-danger'>{aboutUsImageValid()}</span> : ""}

          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Advertise  Image (Recomended Size is 1024px X 720px)</Label>
          <Row>
            <Col md="4">
              <FormGroup>
                {storeVar.aboutUsData?.adImg ? <img className='w-75 h-5-r' src={storeVar.aboutUsData?.adImg} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
              </FormGroup>
            </Col>
            <Col md="4">
              <Dropzone
                className='dropzone dz-clickable'
                onChangeStatus={handleAddImageStatus}
                maxFiles={1}
                multiple={false}
                accept="image/*"
                inputContent='Drop A File'
                styles={{
                  dropzone: { width: '100%', height: 150 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              /></Col>
            <Col md="2">
              <Btn attrBtn={{ color: 'primary', onClick: submitAddvertise }}>Save Changes</Btn>

            </Col>
          </Row>  
          {submitAddStatus && AddImageValid() ? <span className='d-block font-danger'>{AddImageValid()}</span> : ""}

        </CardHeader>
      </Card>
    </Col>
  );
};

export default Blogs;
