import axios from "axios";
import { authHeader } from "../_helper/auth-header";


const rootUrl = 'https://server.way2studyabroad.in:6988/api/v1/';
// const rootUrl = 'http://localhost:3000/api/v1/';

// const rootUrl ='https://way2studydemo.goldensoftwaretechnology.com:7985/api/v1/';




const settingsId = "40364522-775d-4649-8470-153be4bd8854";

const authURL = rootUrl + 'auth';
const dashboardURL = rootUrl + 'dashboard';
const BrandUrl = rootUrl + '';
const leadsURL = rootUrl + 'enquiry';

const faqsURL = rootUrl + 'faq-qna';
const settingsURL = rootUrl + 'settings'
const universityURL = rootUrl + 'university'

const staffURL = rootUrl + 'account';

const blogsURL = rootUrl + 'blogs';
const countryURL = rootUrl + 'country';
const pagesURL = rootUrl + 'pages';
const degreeURL = rootUrl + 'degree';
const levelURL = rootUrl + 'level';
const courseURL = rootUrl + 'course'
const dataCounterURL = rootUrl + 'data-counter'
const homeURL = rootUrl + 'home';
const coreValueURL = rootUrl + 'core-value';
const aboutUsURL =rootUrl + 'aboutus';
const whyUsURL = rootUrl + 'why-us';
const testURL = rootUrl + 'exam';
const docsURL = rootUrl + 'doclist';
const scholarshipURL = rootUrl + 'scholarship';
const staffEnquiryURL = rootUrl + 'staff-enquiry';
const activityLogURL = rootUrl + 'activity-log'
const todoListURL = rootUrl + 'todo-list'



/***** Auth *****/
async function login(loginData) {
  return await axios.post(authURL + '/admin/login', loginData);
}

async function dashboard() {
  return await axios.get(dashboardURL,{
    headers: await authHeader()
  });
}
async function dashboardsStaff() {
  return await axios.get(dashboardURL+ '/staffLeedCount',{
    headers: await authHeader()
  });
}
async function dashboardpie() {
  return await axios.get(dashboardURL + "/orderedProduct");
}


async function getTodoList(){
  return await axios.get(todoListURL,{
    headers: await authHeader()
  });
} 

async function addTodoList(desc){
  return await axios.post(todoListURL,{desc:desc.task},{
    headers: await authHeader()
  });
}
async function todoStatusUpdate(id,status){
  return await axios.put(todoListURL+ '/'+id,{status},{
    headers: await authHeader()
  });
}
// leads

async function leadData(limit, offset, status, keyword, startDate, endDate) {
  return await axios.get(leadsURL + '?limit=' + limit + '&offset=' + offset + '&keyword=' + keyword + '&fromDate=' + startDate + '&toDate=' + endDate + '&status=' + status, {
    headers: await authHeader()
  })
}
async function statusUpdateleads(id, status) {
  return await axios.put(leadsURL + '/' + id, { status: status }, {
    headers: await authHeader()
  })
}


async function createleads(payload) {
  return await axios.post(leadsURL+ '/admin', payload, {
    headers: await authHeader()
  })
}

async function updateLeads(id, fullName, email, phoneNumber, altPhoneNumber, enquiryFor, desc) {
  return await axios.patch(leadsURL + '/' + id, { fullName: fullName, email: email, phoneNumber: phoneNumber, altPhoneNumber: altPhoneNumber, enquiryFor: enquiryFor, desc: desc }, {
    headers: await authHeader()
  })
}
async function updateleadsImage(id, file) {
  return await axios.post(leadsURL + '-doc/' + id, {file }, {
    headers: await authHeader('FormData')
  })
}

async function asignStaff(data) {
  return await axios.post(staffEnquiryURL, data, {
    headers: await authHeader()
  })
}
async function getreminder(limit, offset, enquiryId) {
  return await axios.get(staffEnquiryURL+ '/reminder?limit=' + limit + '&offset=' + offset +'&enquiryId='+ enquiryId , {
    
    headers: await authHeader()
  })
}
async function updateReminder(payload,id) {
  delete payload.id
  return await axios.patch(staffEnquiryURL + '/' + id,payload,{
    headers: await authHeader()
  })
}
async function deleteReminder(id) {
  return await axios.delete(staffEnquiryURL + '/' + id,{
    headers: await authHeader()
  })
}

async function getActivityLog(enquiryId) {
  return await axios.get(activityLogURL + '/'+ enquiryId, {
    headers: await authHeader()
  })
}

async function getBlogs(limit, offset, status) {
  return await axios.get(blogsURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status, {
    headers: await authHeader()
  })
}

async function createBlogs(title, author, desc, tag, date) {
  return await axios.post(blogsURL, { title: title, author: author, desc: desc, tag: tag, date: date }, {
    headers: await authHeader()
  })
}


async function updateBlogsdata(id, title, author, desc, tag, date) {
  return await axios.patch(blogsURL + '/' + id, { title: title, author: author, desc: desc, tag: tag, date: date }, {
    headers: await authHeader()
  })
}

async function statusUpdateBlogs(id, status) {
  return await axios.put(blogsURL + '/' + id, { status: status }, {
    headers: await authHeader()
  })
}


async function updateBlogsImage(id, file) {
  return await axios.put(blogsURL + '/image/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}



async function getCountries(limit, offset, status, keyword) {
  return await axios.get(countryURL + '/all?limit='+limit+'&offset='+offset+'&status='+status+'&keyword='+keyword, {
    headers: await authHeader()
  })
}
async function createCountry(name) {
  return await axios.post(countryURL, { name: name }, {
    headers: await authHeader()
  })
}
async function updateCountrydata(id, name) {
  return await axios.patch(countryURL+'/'+id, { name: name }, {
    headers: await authHeader()
  })
}
async function updateCountriesImage(id, file) {
  return await axios.put(countryURL + '/logo/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}

async function statusUpdateCountry(id, status) {
  return await axios.put(countryURL + '/' + id, { status: status }, {
    headers: await authHeader()
  })
}

async function getCountryDetailsById(id) {
  return await axios.get(countryURL + '-detail/individual/' + id, {
    headers: await authHeader()
  })
}

async function updateCountryDetailsById(id, data) {
  return await axios.patch(countryURL + '-detail/' + id,data, {
    headers: await authHeader()
  })
}

async function updateCountriesBannerImage(id, file) {
  return await axios.put(countryURL + '-detail/banner/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}
async function updateCountriesAboutImage(id, file) {
  return await axios.put(countryURL + '-detail/aboutImage/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}
async function updateCountriesCostImage(id, file) {
  return await axios.put(countryURL + '-detail/costImage/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}


async function getCountryDocListById(id) {
  return await axios.get(docsURL + '/admin/' + id, {
    headers: await authHeader()
  })
}
async function AddCountryDocList(id, desc) {
  return await axios.post(docsURL,{countryId: id, shortDesc: desc}, {
    headers: await authHeader()
  })
}
async function updateCountryDocList(id, desc) {
  return await axios.patch(docsURL + '/'+id, {shortDesc: desc}, {
    headers: await authHeader()
  })
}
async function deleteCountryDocList(id, status) {
  return await axios.put(docsURL + '/'+id, {status: status}, {
    headers: await authHeader()
  })
}
async function putImageDocsRequired(id, file) {
  return await axios.put(docsURL + '/logo/'+id, {file}, {
    headers: await authHeader('FormData')
  })
}

async function getPage() {
  return await axios.get(pagesURL, {
    headers: await authHeader(),
  });
}
async function pagesData(id) {
  return await axios.get(pagesURL + '/' + id, {
    headers: await authHeader(),
  });
}
async function updatePage(id, title, desc) {
  return await axios.patch(pagesURL + '/' + id, { title, desc }, {
    headers: await authHeader(),
  });
}



async function updateBrandsImage(id, file) {
  return await axios.put(BrandUrl + '/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}



// Faq
async function getFaqs(limit, offset, status, keyword) {
  return await axios.get(faqsURL + '/all?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}
async function addFaqs(payload) {
  return await axios.post(faqsURL, payload, {
    headers: await authHeader(),
  });
}
async function updateFaqs(id, payload) {
  delete payload.id
  return await axios.patch(faqsURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}



// settings
async function getSettings() {
  return await axios.get(settingsURL + '/default', {
    headers: await authHeader(),
  });
}
async function updateNewSetting(payload) {
  return await axios.patch(settingsURL + '/' + settingsId, payload, {
    headers: await authHeader(),
  });
}
async function addSettingsLogo(payload) {
  delete payload.id
  return await axios.put(settingsURL + '/logo/', payload, {
    headers: await authHeader("FormData"),
  });
}
async function addSettingsFavicon(payload) {
  delete payload.id
  return await axios.put(settingsURL + '/favIcon/', payload, {
    headers: await authHeader("FormData"),
  });
}



// University
async function getUniversity(limit, offset, countryid, keyword, rank, status) {
  return await axios.get(universityURL + '/all?limit=' + limit + '&offset=' + offset + '&countryId=' + countryid + '&keyword=' + keyword + '&rank=' + rank+ '&status='+status, {
    headers: await authHeader(),
  });
}
async function addUniversity(payload) {
  return await axios.post(universityURL, payload, {
    headers: await authHeader(),
  });
}
async function updateUniversity(id, payload) {
  return await axios.patch(universityURL+ '/'+ id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateUniversity(id, status) {
  return await axios.put(universityURL+ '/status/'+ id, {status: status}, {
    headers: await authHeader(),
  });
}

async function updateUniversityImage(id, file) {
  return await axios.put(universityURL + '/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}
async function updateUniversityBanner(id, file) {
  return await axios.put(universityURL + '/uniBanner/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}

async function getdegree(limit, offset, status, keyword) {
  return await axios.get(degreeURL+'?keyword='+keyword+'&limit=10&status='+status+'&offset=0', { headers: await authHeader(),
  });
}

async function createDegree(name) {
  return await axios.post(degreeURL, { name: name }, {
    headers: await authHeader(),
  });
}

async function updateDegreedata(id, name) {
  return await axios.patch(degreeURL + '/' + id, { name: name }, {
    headers: await authHeader(),
  });
}

async function statusUpdateDegree(id, name) {
  return await axios.put(degreeURL + '/' + id, { status: name }, {
    headers: await authHeader(),
  });
}



async function getlevel(limit, offset, status,  keyword) {
  return await axios.get(levelURL + '?limit=10&offset=0&keyword='+keyword+'&status='+status, {
    headers: await authHeader(),
  });
}
async function createlevel(name) {
  return await axios.post(levelURL, {name:name}, {
    headers: await authHeader(),
  });
}
async function updateLevelData(id, name) {
  return await axios.patch(levelURL+'/'+id, {name:name}, {
    headers: await authHeader(),
  });
}


async function statusUpdateLevel(id, status) {
  return await axios.put(levelURL +'/' + id , {status : status}, {
    headers: await authHeader(),
  });
}
async function getcourse(limit, offset, status,  keyword) {
  return await axios.get(courseURL+ '?status='+status +'&keyword='+keyword, {
    headers: await authHeader(),
  });
}
async function createcourse(data) {
  return await axios.post(courseURL ,data, {
    headers: await authHeader(),
  });
}
async function updatecoursedata(id, data) {
  return await axios.patch(courseURL + '/' +id,data, {
    headers: await authHeader(),
  });
}
async function statusUpdateCourse(id, status) {
  return await axios.put(courseURL + '/status/' +id,{status: status}, {
    headers: await authHeader(),
  });
}

async function updatecourseImage(id, file) {
  return await axios.put(courseURL + '/' +id,{file}, {
    headers: await authHeader('FormData'),
  });
}
async function fetchDataCounter( status) {
  return await axios.get(dataCounterURL+ '/all?status='+status, {
    headers: await authHeader(),
  });
}
async function updateDataCounterdata( id, title, count) {
  return await axios.patch(dataCounterURL+ '/'+ id,{name: title, count: count},{
    headers: await authHeader(),
  });
}

async function updateDataCounterImage(id, file) {
  return await axios.put(dataCounterURL + '/' + id, { file }, {
    headers: await authHeader('FormData')
  })
}

async function fetchHomePage() {
  return await axios.get(homeURL, {
    headers: await authHeader(),
  });
}

async function updatehomePagedata(payload) {
  return await axios.patch(homeURL,payload, {
    headers: await authHeader(),
  });
}

async function updateHomeBannerImage(file) {
  return await axios.put(homeURL+ '/mainBanner',{ file }, {
    headers: await authHeader('FormData'),
  });
}

async function updatehomePageAboutUsImage(file) {
  return await axios.put(homeURL + '/aboutImg', { file }, {
    headers: await authHeader('FormData'),
  });
}

async function updatehomePageFaqImage(file) {
  return await axios.put(homeURL+ '/faqImg',{ file }, {
    headers: await authHeader('FormData'),
  });
}

async function updatehomePageAddImage(file) {
  return await axios.put(homeURL+'/advertiseImg', {file}, {
    headers: await authHeader('FormData'),
  });
}

async function CoreValuesData() {
  return await axios.get(coreValueURL+'?limit=10&offset=0&status=ACTIVE', {
    headers: await authHeader(),
  });
}
async function updatecoreValueData(id, question, answer) {
  return await axios.patch(coreValueURL+ '/'+id ,{question: question, answer: answer, status: 'ACTIVE'} ,{
    headers: await authHeader(),
  });
}

async function getaboutUsdata() {
  return await axios.get(aboutUsURL+ '?status=ACTIVE',{
    headers: await authHeader(),
  });
}
async function updateaboutUsdata(payload) {
  return await axios.patch(aboutUsURL, payload,{
    headers: await authHeader(),
  });
}
async function updateaboutCouncelor1Image(file) {
  return await axios.put(aboutUsURL+'/image1', {file}, {
    headers: await authHeader('FormData'),
  });
}
async function updateaboutCouncelor2Image(file) {
  return await axios.put(aboutUsURL+'/image2', {file}, {
    headers: await authHeader('FormData'),
  });
}
async function updateaboutAddImage(file) {
  return await axios.put(aboutUsURL+'/adImg', {file}, {
    headers: await authHeader('FormData'),
  });
}






// Staff
async function getStaff(limit, offset, status, role, keyword) {
  return await axios.get(staffURL + '/my-staff?limit=' + limit + '&offset=' + offset + '&status=' + status + '&role=' + role + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}

async function addStaff(payload) {
  return await axios.post(staffURL, payload, {
    headers: await authHeader(),
  });
}

async function updateStaff(id, payload) {
  delete payload.id
  return await axios.patch(staffURL + '/detail/' + id, payload, {
    headers: await authHeader(),
  });
}

async function deleteStaff(id) {
  return await axios.delete(staffURL + '/' + id, {
    headers: await authHeader(),
  });
}

async function getwhyUsdata() {
  return await axios.get(whyUsURL + '/admin' , {
    headers: await authHeader(),
  });
}

async function updatewhyUsdata(payload) {
  return await axios.patch(whyUsURL,payload , {
    headers: await authHeader(),
  });
}
async function addwhyUsPerks(payload) {
  return await axios.post(whyUsURL+'-perks',payload , {
    headers: await authHeader(),
  });
}
async function getwhyUsPerksdata() {
  return await axios.get(whyUsURL + '-perks?limit=10&offset&' , {
    headers: await authHeader(),
  });
}

async function updateWhyUsPerksdata(id, payload) {
  return await axios.patch(whyUsURL + '-perks/'+ id,payload , {
    headers: await authHeader(),
  });
}
async function statusUpdateWhyUsPerks(id, status) {
  return await axios.put(whyUsURL + '-perks/'+ id,{status} , {
    headers: await authHeader(),
  });
}


async function getTests(limit, offset, status, keyword) {
  return await axios.get(testURL + '/all?limit='+limit+'&offset='+offset+'&status='+status , {
    headers: await authHeader(),
  });
}
async function createtest(payload) {
  return await axios.post(testURL, payload, {
    headers: await authHeader(),
  });
}
async function updatetestData(id,payload) {
  return await axios.patch(testURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function updateTestStatus(id,status) {
  return await axios.put(testURL + '/status/' + id, {status}, {
    headers: await authHeader(),
  });
}

async function getTestsDetails(id) {
  return await axios.get(testURL + '-detail/admin/'+id , {
    headers: await authHeader(),
  });
}
async function updateTestDetails(id, data) {
  return await axios.patch(testURL + '-detail/'+id, data, {
    headers: await authHeader(),
  });
}

async function updateTestImage(id, file ){
  return await axios.put(testURL + '-detail/banner/'+id, {file}, {
    headers: await authHeader('FormData'),
  });
}

async function getTestSlider(id, limit, offset, status, keyword ){
  return await axios.get(testURL + '-detail-slider/admin/'+id+'?limit='+limit+'&offset='+offset+'&status='+status+'&keyword='+keyword , {
    headers: await authHeader(),
  });
}
async function createtestSliders(payload ){
  return await axios.post(testURL + '-detail-slider',payload, {
    headers: await authHeader(),
  });
}
async function updatetestSliderData(id, payload ){
  return await axios.patch(testURL + '-detail-slider/'+id,payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateExamSlider(id, status ){
  return await axios.put(testURL + '-detail-slider/'+id, {status: status}, {
    headers: await authHeader(),
  });
}
async function updateTestSliderImage(id, file ){
  return await axios.put(testURL + '-detail-slider/sliderImg/'+id, {file}, {
    headers: await authHeader('FormData'),
  });
}

async function getscholarship(limit, offset, status,  keyword) {
  return await axios.get(scholarshipURL + '?limit='+limit+'&offset='+offset+'&keyword='+keyword+'&status='+status , {
    headers: await authHeader(),
  });
}
async function createscholarship(payload) {
  return await axios.post(scholarshipURL,payload , {
    headers: await authHeader(),
  });
}

async function updatescholarshipdata(id, payload) {
  return await axios.patch(scholarshipURL+ '/'+id,payload , {
    headers: await authHeader(),
  });
}
async function statusUpdatescholarship(id, status) {
  return await axios.put(scholarshipURL+ '/'+id,{status} , {
    headers: await authHeader(),
  });
}

export const service = {
  login,
  dashboard,
  dashboardsStaff,
  dashboardpie,


  getTodoList,
  addTodoList,
  todoStatusUpdate,


  leadData,
  createleads,
  updateLeads,
  updateleadsImage,
  asignStaff,
  statusUpdateleads,


  getreminder,
  updateReminder,
  deleteReminder,

  getActivityLog,

  getBlogs,
  createBlogs,
  updateBlogsdata,
  statusUpdateBlogs,
  updateBlogsImage,

  getCountries,  createCountry,  updateCountrydata,  updateCountriesImage,  statusUpdateCountry,
  getCountryDetailsById, updateCountryDetailsById,
  updateCountriesBannerImage,   updateCountriesAboutImage, updateCountriesCostImage, 
  getCountryDocListById, AddCountryDocList, updateCountryDocList, deleteCountryDocList, putImageDocsRequired,

  getPage,
  pagesData,
  updatePage,




  updateBrandsImage,


  getFaqs,
  addFaqs,
  updateFaqs,


  getSettings,
  addSettingsLogo,
  addSettingsFavicon,
  updateNewSetting,


  getUniversity, addUniversity, updateUniversity, statusUpdateUniversity, updateUniversityImage, updateUniversityBanner,

  getdegree, createDegree, updateDegreedata, statusUpdateDegree,

  getlevel, createlevel, updateLevelData, statusUpdateLevel,

  getcourse, createcourse, updatecoursedata, statusUpdateCourse, updatecourseImage,


  fetchDataCounter, updateDataCounterdata, updateDataCounterImage,

  fetchHomePage, updatehomePagedata, updateHomeBannerImage, updatehomePageAboutUsImage, updatehomePageFaqImage, updatehomePageAddImage , 
  CoreValuesData, updatecoreValueData,

  getaboutUsdata, updateaboutUsdata, updateaboutCouncelor1Image, updateaboutCouncelor2Image, updateaboutAddImage,

  getStaff, addStaff, updateStaff, deleteStaff,

  getwhyUsdata, updatewhyUsdata, addwhyUsPerks, getwhyUsPerksdata, updateWhyUsPerksdata, statusUpdateWhyUsPerks,

  getTests, createtest, updatetestData,  updateTestStatus,
  getTestsDetails, updateTestDetails, updateTestImage,
  getTestSlider, createtestSliders, updatetestSliderData, statusUpdateExamSlider, updateTestSliderImage,

  getscholarship, createscholarship, updatescholarshipdata, statusUpdatescholarship,
}
