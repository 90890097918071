import React, { Fragment, useEffect, useState, } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, Trash2 } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { addFaqs, getFaqs, updateFaqs, ModalToggle, isOpenModal, statusUpdateFaqs } from '../../store/faqsSlice';
import Pagination from '../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';


const FaqsTable = () => {
  const storeVar = useSelector(state => state.faqs)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    faqsId: null,
    faqStatus: 'ACTIVE',
    question: '',
    answer: '',
    faqType: 'HOME',
    pageId: '8'
  });

  useEffect(() => {
    dispatch(getFaqs(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
  }, []);


  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    dispatch(getFaqs(formVar.limit, formVar.offset, formVar.status, e.target.value))
  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getFaqs(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getFaqs(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      faqsId: data.id,
      faqStatus: data.status,
      question: data.question,
      answer: data.answer,
      faqType: data.tag,
      modalTitle: 'Edit FAQ'
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      question: '',
      answer: '',
      tag: '',
      editState: false,
      modalTitle: 'Add FAQ',
    }))
  }

  const submitDegree = () => {
    if (formVar.editState) {
      if (answerValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(updateFaqs({ id: formVar.faqsId, question: formVar.question, answer: formVar.answer, status: formVar.faqStatus, tag: formVar.faqType }))
    } else {
      if (questionValid() || statusValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(addFaqs({ question: formVar.question, answer: formVar.answer, status: formVar.faqStatus, tag: formVar.faqType, pageId: formVar.pageId }))
    }
  }


  const questionValid = () => {
    if (!formVar.question) {
      return "question name is required";
    }
  }
  const statusValid = () => {
    if (!formVar.status) {
      return "Status is required";
    }
  }
  // const faqForValid = () => {
  //   if (!formVar.faqFor) {
  //     return "FAQ for is required";
  //   }
  // }
  const answerValid = () => {
    if (!formVar.answer) {
      return "Answer is required";
    }
  }
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusUpdateFaqs(data.id))

        }
      });
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add FAQ
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Question</th>
                  <th scope='col'>Answer</th>
                  <th scope='col'>Tag</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.faqsData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.question} </td>
                    <td>{item.answer}</td>
                    <td>{item.tag}</td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                        {/* <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar.faqsData.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalFaq}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Question</Label>
            <Input className="form-control" type="text" placeholder='Enter Question' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, question: e.target.value }))} value={formVar.question} />
            {submit && questionValid() ? <span className='d-block font-danger'>{questionValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Answer</Label>
            <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, answer: e.target.value }))} value={formVar.answer} />

            <Label className="col-form-label" for="recipient-name">Tag</Label>
            <Input className="form-control form-control-inverse btn-square" name="select" type="select"
              value={formVar.faqType} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, faqType: e.target.value }))}>
              <option value='HOME'>HOME</option>
              <option value='FAQ'>FAQ</option>
              <option value='UNIVERSITY'>UNIVERSITY</option>
              <option value='COUNTRY'>COUNTRY</option>
            </Input>
            {formVar.editState &&
              <Row>
                <Col md="6">
                  <Label className="col-form-label" for="recipient-name">Status</Label>
                  <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                    value={formVar.faqStatus} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, faqStatus: e.target.value }))}>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DEACTIVE'>DEACTIVE</option>
                    <option value='PENDING'>PENDING</option>
                  </Input>
                  {submit && statusValid() ? <span className='d-block font-danger'>{statusValid()}</span> : ""}
                </Col>
              </Row>
            }

          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default FaqsTable;
