import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { updateCountryDetails } from '../../store/countriesSlice';
import { useDispatch, useSelector } from 'react-redux';


const CountryPageModel = ({ props }) => {
    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [formVar, setFormVar] = useState({
        heading: "",
        desc: "",
        aboutHeading: "",
        aboutDesc: "",
        costHeading: "",
        costDesc: "",
        docHeading1: "",
        docHeading2: "",
        docDesc: "",
        metaTag: '',
        metaDesc: '',
        metaTitle: '',
        metaKeyword: '',

    });
    const CountryId = props?.country?.id;
    useEffect(() => {
        if (props) {
            setFormVar({
                heading: props?.heading || '',
                desc: props?.desc || '',
                aboutHeading: props?.aboutHeading || '',
                aboutDesc: props?.aboutDesc || '',
                costHeading: props?.costHeading || '',
                costDesc: props?.costDesc || '',
                docHeading1: props?.docHeading1 || '',
                docHeading2: props?.docHeading2 || '',
                docDesc: props?.docDesc || '',
                metaTag: props.metaTag || '',
                metaDesc: props.metaDesc || '',
                metaTitle: props.metaTitle || '',
                metaKeyword: props.metaKeyword || '',

            });
        }
    }, [props]);

    const submitNewSettings = () => {

        if (titleValid()) {
            setSubmit(true)
            return null
        }
        if (descvalid()) {
            setSubmit(true)
            return null
        }
        if (aboutHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (AboutDescValid()) {
            setSubmit(true)
            return null
        }
        if (costHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (costDescValid()) {
            setSubmit(true)
            return null
        }
        if (docHeading1Valid()) {
            setSubmit(true)
            return null
        }
        if (docHeading2Valid()) {
            setSubmit(true)
            return null
        }
        if (docDescValid()) {
            setSubmit(true)
            return null
        }
        setSubmit(false)


        dispatch(updateCountryDetails(CountryId, formVar));
    }
    const titleValid = () => {
        if (!formVar.heading) {
            return "Header Title is required";
        }
    }
    const descvalid = () => {
        if (!formVar.desc) {
            return "Description is required";
        }
    }
    const aboutHeadingValid = () => {
        if (!formVar.aboutHeading) {
            return "About Heading is required";
        }
    }
    const AboutDescValid = () => {
        if (!formVar.aboutDesc) {
            return "About Description is required";
        }
    }
    const costHeadingValid = () => {
        if (!formVar.costHeading) {
            return "About Heading is required";
        }
    }
    const costDescValid = () => {
        if (!formVar.costDesc) {
            return "About Description is required";
        }
    }
    const docHeading1Valid = () => {
        if (!formVar.costHeading) {
            return "Documents Heading 1 is required";
        }
    }
    const docHeading2Valid = () => {
        if (!formVar.costHeading) {
            return "Documents Heading 2 is required";
        }
    }
    const docDescValid = () => {
        if (!formVar.costHeading) {
            return "Documents Description is required";
        }
    }


    return (
        <>

            <Label className="col-form-label fw-semibold f-20" for="recipient-name">Header </Label>
            <Row>
                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name"> Header Title</Label>
                        <Input className="form-control" type="text" placeholder='Enter Header Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, heading: e.target.value }))} value={formVar?.heading} />
                        {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}

                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">Description</Label>
                        <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
                        {submit && descvalid() ? <span className='d-block font-danger'>{descvalid()}</span> : ""}

                    </FormGroup>
                </Col>
            </Row>
            <Label className="col-form-label fw-semibold f-20" for="recipient-name">About </Label>
            <Row>
                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name"> About Heading</Label>
                        <Input className="form-control" type="text" placeholder='Enter About Heading' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, aboutHeading: e.target.value }))} value={formVar?.aboutHeading} />
                        {submit && aboutHeadingValid() ? <span className='d-block font-danger'>{aboutHeadingValid()}</span> : ""}
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">About Description</Label>
                        <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, aboutDesc: e.target.value }))} value={formVar.aboutDesc} />
                        {submit && AboutDescValid() ? <span className='d-block font-danger'>{AboutDescValid()}</span> : ""}

                    </FormGroup>
                </Col>
            </Row>
            <Label className="col-form-label fw-semibold f-20" for="recipient-name">Cost Of Living </Label>
            <Row>
                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name"> Cost Heading</Label>
                        <Input className="form-control" type="text" placeholder='Enter Cost Heading' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, costHeading: e.target.value }))} value={formVar?.costHeading} />
                        {submit && costHeadingValid() ? <span className='d-block font-danger'>{costHeadingValid()}</span> : ""}
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">Cost Description</Label>
                        <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, costDesc: e.target.value }))} value={formVar.costDesc} />
                        {submit && costDescValid() ? <span className='d-block font-danger'>{costDescValid()}</span> : ""}

                    </FormGroup>
                </Col>
            </Row>
            <Label className="col-form-label fw-semibold f-20" for="recipient-name">List of Documents </Label>

            <Row>
                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">  Heading 1</Label>
                        <Input className="form-control" type="text" placeholder='Enter  Heading 1' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, docHeading1: e.target.value }))} value={formVar?.docHeading1} />
                        {submit && docHeading1Valid() ? <span className='d-block font-danger'>{docHeading1Valid()}</span> : ""}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">  Heading 2</Label>
                        <Input className="form-control" type="text" placeholder='Enter  Heading 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, docHeading2: e.target.value }))} value={formVar?.docHeading2} />
                        {submit && docHeading2Valid() ? <span className='d-block font-danger'>{docHeading2Valid()}</span> : ""}
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col >
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name"> Description</Label>
                        <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, docDesc: e.target.value }))} value={formVar.docDesc} />
                        {submit && docDescValid() ? <span className='d-block font-danger'>{docDescValid()}</span> : ""}

                    </FormGroup>
                </Col>
            </Row>
            <Label className="col-form-label fw-semibold f-20" for="recipient-name">Meta Section</Label>
            <Row>

                <Label className="col-form-label" for="recipient-name">Meta Tag</Label>
                <Input className="form-control" type="text" placeholder='Enter Meta Meta Tag' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaTag: e.target.value }))} value={formVar.metaTag} />
                <Label className="col-form-label" for="recipient-name">Description</Label>
                <Input className="form-control" type="text" placeholder='Enter Meta Description' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaDesc: e.target.value }))} value={formVar.metaDesc} />
                <Label className="col-form-label" for="recipient-name">Title</Label>
                <Input className="form-control" type="text" placeholder='Enter Meta Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaTitle: e.target.value }))} value={formVar.metaTitle} />
                <Label className="col-form-label" for="recipient-name">Keyword</Label>
                <Input className="form-control" type="text" placeholder='Enter Meta Keyword' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaKeyword: e.target.value }))} value={formVar.metaKeyword} />
            </Row>


            <ModalFooter style={{ justifyContent: "center" }}>
                <Btn attrBtn={{ color: 'primary', onClick: submitNewSettings }}>Save </Btn>
            </ModalFooter>




        </>
    );
};

export default CountryPageModel;
