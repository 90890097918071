import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { updateTestDetails } from '../../store/testSlice';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Tests = ({ props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    heading: '',
    shortDesc: '',
    title: '',
    desc: '',
    duration: '',
    regFree: '',
    scoreValidity: '',
    question1: '',
    question2: '',
    amswer: '',
    structure: '',
    structureDesc: '',
    subStructure: '',
    subStructureDesc: '',
    subStructure2: '',
    subStructureDesc2: '',
    register: '',
    registerDesc: '',
    score: '',
    scoreDesc: '',
    metaTag: '',
    metaTitle: '',
    metaKeyword: '',
    metaDesc: '',
  });
  const examid = props?.id;

  useEffect(() => {
    if (props) {
      setFormVar({
        heading: props.heading || '',
        shortDesc: props.shortDesc || '',
        title: props.title || '',
        desc: props.desc || '',
        duration: props.duration || '',
        regFree: props.regFree || '',
        scoreValidity: props.scoreValidity || '',
        question1: props.question1 || '',
        question2: props.question2 || '',
        amswer: props.amswer || '',
        structure: props.structure || '',
        structureDesc: props.structureDesc || '',
        subStructure: props.subStructure || '',
        subStructureDesc: props.subStructureDesc || '',
        subStructure2: props.subStructure2 || '',
        subStructureDesc2: props.subStructureDesc2 || '',
        register: props.register || '',
        registerDesc: props.registerDesc || '',
        score: props.score || '',
        scoreDesc: props.scoreDesc || '',
        metaTag: props.metaTag || '',
        metaTitle: props.metaTitle || '',
        metaKeyword: props.metaKeyword || '',
        metaDesc: props.metaDesc || '',
      });
    }
  }, [props]);

  const onFocus = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, editerData: evt }))
  }



  const onChange = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, amswer: evt }))
  };
  const onChangeScore = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, scoreDesc: evt }))
  };
  const onChangeRegister = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, registerDesc: evt }))
  };
  const submitTestDetails = () => {

    dispatch(updateTestDetails(examid, formVar))
  }

  const nameValid = () => {
    if (!formVar.testName) {
      return "Test Name is required";
    }
  }
  return (
    <Fragment>
      <Form>
        <FormGroup>
          <Label className="col-form-label" for="recipient-name">Heading</Label>
          <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, heading: e.target.value }))} value={formVar.heading} />
          {submit && nameValid() ? <span className='d-block font-danger'>{nameValid()}</span> : ""}
          <Label className="col-form-label" for="recipient-name">Header Description</Label>
          <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, shortDesc: e.target.value }))} value={formVar.shortDesc} />
          <Label className="col-form-label" for="recipient-name">Title</Label>
          <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar.title} />
          {submit && nameValid() ? <span className='d-block font-danger'>{nameValid()}</span> : ""}
          <Label className="col-form-label" for="recipient-name">Title Description</Label>
          <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
          <Row>
            <Col md='6'>

              <Label className="col-form-label" for="recipient-name">Duration Of Test</Label>
              <Input className="form-control" type="text" placeholder='Enter Duration Of Test' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, duration: e.target.value }))} value={formVar.duration} />
            </Col>
            <Col md='6'>

              <Label className="col-form-label" for="recipient-name">Registration Fees</Label>
              <Input className="form-control" type="text" placeholder='Enter Registration Fees' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, regFree: e.target.value }))} value={formVar.regFree} />
            </Col>
          </Row>
          <Row>
            <Col md='6'>

              <Label className="col-form-label" for="recipient-name">Score Validity</Label>
              <Input className="form-control" type="text" placeholder='Enter Score Validity' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, scoreValidity: e.target.value }))} value={formVar.scoreValidity} />
            </Col>
            <Col md='6'>

              <Label className="col-form-label" for="recipient-name">Question 1</Label>
              <Input className="form-control" type="text" placeholder='Enter Question 1' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, question1: e.target.value }))} value={formVar.question1} />
            </Col>
          </Row>



          <Label className="col-form-label" for="recipient-name">Question 2</Label>
          <Input className="form-control" type="text" placeholder='Enter Question 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, question2: e.target.value }))} value={formVar.question2} />
          <Label className="col-form-label" for="recipient-name">Answer</Label>

          <CardBody>
            <CKEditor
              editor={ClassicEditor}
              data={formVar.amswer}
              onReady={editor => {
                onFocus(editor)
              }}
              onChange={(event, editor) => {
                onChange(editor);
              }}
              onBlur={(event, editor) => {
              }}
              onFocus={(event, editor) => {
                onFocus(editor)
              }}
            />
          </CardBody>
          <Label className="col-form-label" for="recipient-name">Type Title</Label>
          <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, structure: e.target.value }))} value={formVar.structure} />

          <Label className="col-form-label" for="recipient-name"> Type Title Description</Label>
          <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, structureDesc: e.target.value }))} value={formVar.structureDesc} />
          <Label className="col-form-label" for="recipient-name">Sub Type Title 1</Label>
          <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subStructure: e.target.value }))} value={formVar.subStructure} />

          <Label className="col-form-label" for="recipient-name"> Sub Type 1 Description</Label>
          <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subStructureDesc: e.target.value }))} value={formVar.subStructureDesc} />
          <Label className="col-form-label" for="recipient-name">Sub Type Title 2</Label>
          <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subStructure2: e.target.value }))} value={formVar.subStructure2} />

          <Label className="col-form-label" for="recipient-name">Sub Type 2 Desc</Label>
          <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subStructureDesc2: e.target.value }))} value={formVar.subStructureDesc2} />
          <Label className="col-form-label" for="recipient-name">Score Heading</Label>
          <Input className="form-control" type="text" placeholder='Enter Question 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, score: e.target.value }))} value={formVar.score} />
          <Label className="col-form-label" for="recipient-name">Score Desc</Label>

          <CardBody>
            <CKEditor
              editor={ClassicEditor}
              data={formVar.scoreDesc}
              onReady={editor => {
                onFocus(editor)
              }}
              onChange={(event, editor) => {
                onChangeScore(editor);
              }}
              onBlur={(event, editor) => {
              }}
              onFocus={(event, editor) => {
                onFocus(editor)
              }}
            />
          </CardBody>
          <Label className="col-form-label" for="recipient-name">Register Heading</Label>
          <Input className="form-control" type="text" placeholder='Enter Question 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, register: e.target.value }))} value={formVar.register} />
          <Label className="col-form-label" for="recipient-name">Register Desc</Label>

          <CardBody>
            <CKEditor
              editor={ClassicEditor}
              data={formVar.registerDesc}
              onReady={editor => {
                onFocus(editor)
              }}
              onChange={(event, editor) => {
                onChangeRegister(editor);
              }}
              onBlur={(event, editor) => {
              }}
              onFocus={(event, editor) => {
                onFocus(editor)
              }}
            />
          </CardBody>
          <Label className="col-form-label fw-semibold f-20" for="recipient-name">Meta Section</Label>
          <Row>
            <Label className="col-form-label" for="recipient-name">Meta Tag</Label>
            <Input className="form-control" type="text" placeholder='Enter Meta Tag' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaTag: e.target.value }))} value={formVar.metaTag} />
            <Label className="col-form-label" for="recipient-name">Meta Title</Label>
            <Input className="form-control" type="text" placeholder='Enter Meta Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaTitle: e.target.value }))} value={formVar.metaTitle} />
            <Label className="col-form-label" for="recipient-name">Meta KeyWord</Label>
            <Input className="form-control" type="text" placeholder='Enter Meta KeyWord' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaKeyword: e.target.value }))} value={formVar.metaKeyword} />
            <Label className="col-form-label" for="recipient-name">Meta Description</Label>
            <Input className="form-control" type="text" placeholder='Enter Meta Description' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, metaDesc: e.target.value }))} value={formVar.metaDesc} />
          </Row>

        </FormGroup>
      </Form>
      <Btn attrBtn={{ color: 'primary', onClick: submitTestDetails }}>Save </Btn>
    </Fragment>
  );
};

export default Tests;
