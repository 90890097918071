export const classes = [
  { admin: 'compact-wrapper' },
  { Style2: 'only-body' },
  { Style3: 'compact-wrapper modern-type' },
  { Style4: 'horizontal-wrapper material-type' },
  { Style5: 'compact-wrapper dark-sidebar' },
  { Style6: 'compact-sidebar' },
  { Style7: 'compact-wrapper color-sidebar' },
  { Style8: 'compact-sidebar compact-small' },
  { Style9: 'compact-wrapper box-layout' },
  { Style10: 'horizontal-wrapper enterprice-type' },
  { Style11: 'compact-sidebar compact-small material-icon' },
  { Style12: 'horizontal-wrapper enterprice-type advance-layout' }
];