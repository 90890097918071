import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AboutUsPageModel from '../../Components/AboutUs/AboutUsModel';
import { fetchaboutUs } from '../../store/aboutUsSlice';


const AboutUs = () => {
  const dispatch = useDispatch();
  const storeVar = useSelector(state => state.aboutUs);
  
  useEffect(() => {
    dispatch(fetchaboutUs());
  }, []);

  return (
    <Fragment>
      <AboutUsPageModel props={storeVar?.aboutUsData} />
    </Fragment>
  );
};

export default AboutUs;
