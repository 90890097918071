import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import TestDetails from './TestDetails';
const Level = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Test Details' mainTitle='Test Details' />
      <Container fluid={true}>
        <Row>
          <TestDetails/>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Level;
