
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,
  countriesDataById: {},
  countriesData: [],
  
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setcountries(state, { payload }) {
      state.countriesData = payload.result;
      state.countriessCount = payload.total;
    },
    setbrand(state, { payload }) {
      state.brandData = payload;
    },
    updateCountriesData(state, { payload }) {
      const objIndex = state.countriesData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.countriesData[objIndex].name = payload.name
      }
    },
    getCountriesDataById(state, { payload }) {
      state.countriesDataById = payload;
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isOpenDetailModal(state, { payload }) {
      state.isOpenDetailModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    ModalDetailsToggle(state, { payload }) {
      state.isOpenDetailModal = !state.isOpenDetailModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    },
    setDocListData(state, { payload }) {
      state.DocListData = payload;
    },
    pushDocListData(state, { payload }) {
      state.DocListData.push(payload)
    },
    UpdateDocListData(state, { payload }) {
      const objIndex = state.DocListData.findIndex((obj) => obj.id === payload.id);
      if(state.DocListData[objIndex].status !== payload.status) {
        state.DocListData.splice(objIndex, 1)
      }
      else {
        state.DocListData[objIndex]=payload
      }
    }, 
    setClearState(state, { payload }) {
      state.error = ''
      state.isOpenModal = false
      state.isStatusOpenModal = false
      state.isImageOpenModal = false
      state.countriesDataById = null    },
  },
});
export const { setcountries, setbrand, updateCountriesData, getCountriesDataById, DeleteBrandsData, isOpenModal, isOpenDetailModal, isImageOpenModal, 
  ModalToggle, ModalDetailsToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle, setClearState,
  setDocListData, UpdateDocListData, pushDocListData } = countriesSlice.actions;
export default countriesSlice.reducer;
export function fetchCountries(limit, offset, status, keyword) {
  return async function fetchcountriesThunk(dispatch, getState) {
    try {
      await service.getCountries(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setcountries(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}
export function addCountry(name) {
  return async function addBlogssThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.createCountry(name).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateCountry(id, name) {
  return async function updateBlogsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateCountrydata(id, name).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageCountry(id, file) {

  return async function updateCountryImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateCountriesImage(id, file).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function statusUpdateCountryStatus(payload) {
  return async function statusUpdateCountryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCountry(payload.id, payload.status).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeleteCountryStatus(payload) {
  return async function statusDeleteCountrysThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCountry(payload.id, payload.status).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function fetchCountryDetailsById(id) {
  return async function fetchCountryDetailsByIdThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.getCountryDetailsById(id).then(
        (response) => {
          dispatch(getCountriesDataById(response.data))
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function updateCountryDetails(id, data) {
  return async function UpdateCountryDetailsByIdThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateCountryDetailsById(id, data).then(
        (response) => {
          dispatch(getCountriesDataById(response.data))
          dispatch(ModalDetailsToggle())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')

        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageBanner(id, file) {

  return async function updateBannerImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateCountriesBannerImage(id, file).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageAbout(id, file) {

  return async function updateAboutImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateCountriesAboutImage(id, file).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageCost(id, file) {

  return async function updateCostImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateCountriesCostImage(id, file).then(
        (response) => {
          dispatch(updateCountriesData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function fetchCountryDocListById(id) {
  return async function fetchCountryDocListByIdThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.getCountryDocListById(id).then(
        (response) => {
          dispatch(setDocListData(response.data))
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function addDocsRequired(id, desc) {
  return async function addDocsRequiredThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.AddCountryDocList(id, desc).then(
        (response) => {

          dispatch(pushDocListData(response.data))
          dispatch(ModalToggle())
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateDocsRequired(id, desc) {
  return async function updateDocsRequiredThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateCountryDocList(id, desc).then(
        (response) => {

          dispatch(UpdateDocListData(response.data))
          dispatch(ModalToggle())
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteDocsRequired(id, status) {
  return async function deleteDocsRequiredThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteCountryDocList(id, status).then(
        (response) => {
          dispatch(UpdateDocListData(response.data))
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageDocsRequired(id, file) {
  return async function updateImageDocsRequiredThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.putImageDocsRequired(id, file).then(
        (response) => {
          dispatch(UpdateDocListData(response.data))
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
