import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  settingsData: [],
  settingAllData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
  isImageOpenModal:false,
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettingsData(state, { payload }) {
      state.settingsData = payload.result
      state.settingAllData = payload
    },
    updateNewSettingsAll(state, { payload }) {
      // state.settingsData = payload.result
      state.settingAllData = payload
    },

    updateSettingsData(state, { payload }) {
      const objIndex = state.settingsData.findIndex((obj) => obj.id === payload.id);
      if(objIndex>=0){
        state.settingsData[objIndex]=payload
      }
    },
    updateSettingsStatus(state,{payload}){
      const objIndex = state.settingsData.findIndex((obj) => obj.id === payload.id);
      if(objIndex>=0){
        state.settingsData[objIndex].status=payload.status
      }
    },
    updateSettingsBanner(state, { payload }) {
      const objIndex = state.settingsData.findIndex((obj) => obj.id === payload.id);
      if(objIndex>=0){
        state.settingsData[objIndex].banner1=payload.banner1?payload.banner1:state.settingsData[objIndex].banner1
        state.settingsData[objIndex].banner2=payload.banner2?payload.banner2:state.settingsData[objIndex].banner2
      }
    },
    updateSettingsLogo(state, { payload }) {
      state.settingsData.logo = payload
    },
    updateSettingsFavicon(state, { payload }) {
      state.settingsData.favIcon = payload
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isOpenImageModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    imageToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  }
})

export const { setSettingsData, updateNewSettingsAll, updateSettingsData, isOpenModal, ModalToggle,isOpenStatusModal,
  statusToggle,isOpenImageModal,imageToggle,updateSettingsBanner,updateSettingsStatus, updateSettingsLogo, updateSettingsFavicon } = settingsSlice.actions;

export default settingsSlice.reducer;

/*LOGIN GET OTP*/
export function getSettings(limit, offset, status, keyword) {
  return async function getSettingsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getSettings(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setSettingsData(response.data))
          
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function addState(payload) {
  return async function addStateThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addState(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateState(payload) {
  return async function updateStateThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateState(payload.id,payload.name).then(
        (response) => {
          dispatch(updateSettingsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateSettings(payload) {
  return async function statusUpdateSettingsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateSettings(payload.id,payload.status).then(
        (response) => {
          dispatch(updateSettingsStatus(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function addSettingsLogo(payload) {
  return async function addSettingsLogoThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addSettingsLogo(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(imageToggle())
          dispatch(updateSettingsLogo(response.data))
          
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
      
    }
  }
}
export function addSettingsFavicon(payload) {
  return async function addSettingsFaviconThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addSettingsFavicon(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(imageToggle())
          dispatch(updateSettingsFavicon(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function updateNewSettingData(payload) {
  return async function updateNewSettingDataThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateNewSetting(payload).then(
        (response) => {
          dispatch(updateNewSettingsAll(response.data))
          dispatch(setLoading(false))
          // dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
