import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Table, Card, CardHeader } from 'reactstrap';
import { Breadcrumbs, H6 } from '../../../AbstractElements';
import { SmallWidgetsData, SocialWidgetData } from '../../../Data/Social';
// import { dashboardsData, dashboardpie } from '../../../store/dashboardSlice';
import AllCampaigns from './AllCampaigns';
import FacebookCampaign from './FacebookCampaign';
import FollowerGender from './FollowerGender';
import InstagramSubscription from './InstagramSubscription';
import MobileAppCard from './MobileAppCard';
import SmallWidgets from './SmallWidgets';
import SocialProfileCard from './SocialProfileCard';
import SocialWidget from '../../Common/CommonWidgets/SocialWidget';
import Views from './Views';
import MonthlyProfits from './MonthlyProfits';
import TodoContain from '../../Todo';
import { useNavigate } from "react-router-dom";
import { dashboardsData, dashboardsStaffData, getTodoList } from '../../../store/dashboardSlice';

const Social = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate()

  const storeVar = useSelector(state => state.dashboard);
  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedOneMonthAgo = `/${oneMonthAgo.getFullYear()}-${(oneMonthAgo.getMonth() + 1).toString().padStart(2, '0')}-${oneMonthAgo.getDate().toString().padStart(2, '0')}`;
    const formattedToday = `/${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}/`;
    // dispatch(dashboardsData(formattedOneMonthAgo + formattedToday));
    dispatch(dashboardsData());
    if (localStorage.getItem('roles') === 'ADMIN') {
      dispatch(dashboardsStaffData())
    }
    dispatch(getTodoList())
    // dispatch(dashboardpie());
  }, [])
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' parent='Dashboard' title='' />
      <Container fluid={true}>
        <Row>
          <Col xxl='6' xl='6' >
            <Row>
              <Col md='4' sm='6' >
                <SocialWidget data={storeVar?.activeLeeds} staff={false} color={"#9186ff"} increment={20} title={'Active Leads'} />
              </Col>
              <Col md='4' sm='6' >
                <SocialWidget data={storeVar?.freshLeeds} staff={false} color={"#2fff1b"} increment={15} title={'Fresh Leads'} />
              </Col>
              <Col md='4' sm='6' >
                <SocialWidget data={storeVar?.followUpToday} staff={false} color={"#60dcff"} increment={20} title={'Followup Due Today'} />
              </Col>
              <Col md='4' sm='6' >
                <SocialWidget data={storeVar?.followUpTomorrow} staff={false} color={"#ff4d7b"} increment={17} title={'Followup Due Tomorrow'} />
              </Col>
              <Col md='4' sm='6' >
                <SocialWidget data={storeVar?.followUpLater} staff={false} color={"#FFA941"} increment={54} title={'Follow Up Later'} />
              </Col>
            </Row>
            <div className='p-3 mb-2 bg-body rounded bg-white shadow-sm d-flex align-items-center'>
              <H6>Staff Leads Count</H6>
            </div>
            <Row>
              {/* <Col md='12' sm='12'> */}
              {
                localStorage.getItem('roles') === 'ADMIN' && (
                  // <Card>
                  //   <CardHeader>
                  //     <H6>Staff Leads Count</H6>
                  //   </CardHeader>
                  //   <div className='table-responsive'>
                  //     <Table hover={true} className='table-border-horizontal table-light'>
                  //       <thead>
                  //         <tr>
                  //           <th scope='col'>Sl.No</th>
                  //           <th scope='col'>Name</th>
                  //           <th scope='col'>Total Leads</th>
                  //         </tr>
                  //       </thead>
                  //       <tbody>
                  //         {storeVar.dashboardStaffData && storeVar.dashboardStaffData?.map((item, index) => (
                  //           <tr key={index}>
                  //             <th scope='row'>{index + 1}</th>
                  //             <td>{item.account_staffName}</td>
                  //             <td>{item.leedCount}</td>
                  //           </tr>
                  //         ))}
                  //       </tbody>
                  //     </Table>
                  //   </div>
                  // </Card>
                  storeVar.dashboardStaffData && storeVar.dashboardStaffData?.map((item, index) => (
                    <Col md='4' sm='6' key={index}>
                      <SocialWidget data={item.leedCount} staff={true} color={"#FFA941"} increment={54} title={item.account_staffName} />
                    </Col>
                  ))
                )
              }
              {/* </Col> */}
            </Row>
          </Col>
          <Col xl='6'>
            <TodoContain />
            <Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Social;
