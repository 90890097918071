import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, Image, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { fetchcourse, addcourse, updateCourse, isOpenModal, isImageOpenModal, ModalToggle, isOpenStatusModal, statusToggle, statusUpdateCourseStatus, statusDeleteCourseStatus, updateImageCourse } from '../../store/courseSlice';
import { fetchlevel } from '../../store/levelSlice';
import { getUniversities } from '../../store/universitiesSlice';
import { Typeahead } from 'react-bootstrap-typeahead';
import SweetAlert from 'sweetalert2';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchdegree } from '../../store/degreeSlice';



const Degree = () => {
  const storeVar = useSelector(state => state.course)
  const universityVar = useSelector(state => state.universities)
  const levelVar = useSelector(state => state.level)
  const degreeVar = useSelector(state => state.degree)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const Imagetoggle = () => dispatch(isImageOpenModal());
  const statusModalToggle = () => dispatch(statusToggle());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [extractedIds, setextractedIds] = useState([])
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 500;

  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    courseName: '',
    fees: '',
    duration: '',
    levelId: '',
    degreeId: '',
    universityId: '',
    description: '',
    featured: 'false',
    courseId: '',
    selectedId: [],
    bannerFile: null,
    bannerImageURL: null,


  });

  useEffect(() => {
    dispatch(fetchcourse(formVar.limit, formVar.offset, formVar.status, formVar.keyword,));
    dispatch(fetchlevel('90', '0', 'ACTIVE', ''));
    dispatch(getUniversities('90', '0', "[]", '', '', 'ACTIVE'))
    dispatch(fetchdegree('99', '0', 'ACTIVE', ''));



  }, []);


  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(fetchcourse(formVar.limit, formVar.offset, formVar.status, keyword))
    }, typingDelay);
    setTypingTimer(timer);
  };
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeleteCourseStatus(data.id, 'DELETED'))

        }
      });
  }
  const submitStatus = () => {
    dispatch(statusUpdateCourseStatus({ id: formVar.courseId, status: stateStatus }))
  }
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchcourse(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };

  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))

    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      courseName: data.courseName,
      courseId: data.id,
      fees: data.fees || 0,
      duration: data.duration || 0,
      description: data.desc,
      levelId: data.levelId,
      degreeId: data.degreeId,
      modalTitle: 'Edit Course',
      featured: data.top,
      // selectedId: [],

    }))

    if (data && data.uniCourse) {
      formVar.selectedId = []
      data?.uniCourse.forEach(elem => {
        if (elem.university) {
          formVar.selectedId.push(elem.university);
        } else {
          setFormVar((prevFormVar) => ({
            ...prevFormVar,
            selectedId: []
          }));
        }
      });
    }
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Course',
      courseName: '',
      fees: '',
      duration: '',
      levelId: '',
      degreeId: '',
      selectedId: [],
      description: '',
      featured: 'false',
      courseId: '',
    }))
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      courseId: data.id,
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const submitLevel = () => {

    if (titleValid()) {
      setSubmit(true)
      return null
    }
    if (feesValid()) {
      setSubmit(true)
      return null
    }
    if (durationValid()) {
      setSubmit(true)
      return null
    }
    if (descriptionValid()) {
      setSubmit(true)
      return null
    }
    if (levelValid() || degreeValid()) {
      setSubmit(true)
      return null
    }
    if (universityValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    const data = {
      "courseName": formVar.courseName,
      "desc": formVar.description,
      "top": formVar.featured,
      "fees": formVar.fees,
      "duration": formVar.duration,
      "levelId": formVar.levelId,
      "degreeId": formVar.degreeId,
      "universityId": extractedIds,
    }
    if (formVar.editState) {
      dispatch(updateCourse(formVar.courseId, data))

    } else {
      const dataa = {
        "courseName": formVar.courseName,
        "desc": formVar.description,
        "top": formVar.featured,
        "fees": formVar.fees,
        "duration": formVar.duration,
        "levelId": formVar.levelId,
        "degreeId": formVar.degreeId,
        "universityId": extractedIds
      }
      dispatch(addcourse(dataa))
    }
  }

  const handleSelect = (e) => {
    // setextractedIds(e.map(item => {
    //   return {
    //     universityId: item.id
    //   };
    // }))
    const extrac = e.map(item => ({
      universityId: item.id
    }));
    const universityIds = e.map(university => university.id);

    setextractedIds(universityIds);
  }

  const titleValid = () => {
    if (!formVar.courseName) {
      return "Course Name is required";
    }
  }
  const feesValid = () => {
    if (!formVar.fees) {
      return "Fees is required";
    }
  }
  const durationValid = () => {
    if (!formVar.duration) {
      return "Duration is required";
    }
  }
  const descriptionValid = () => {
    if (!formVar.description) {
      return "Description is required";
    }
  }
  const levelValid = () => {
    if (!formVar.levelId) {
      return "Level is required";
    }
  }
  const degreeValid = () => {
    if (!formVar.degreeId) {
      return "Degree is required";
    }
  }
  const universityValid = () => {
    if (!extractedIds) {
      return "University is required";
    }
  }
  const ImageEditBannerModal = (data) => {
    dispatch(isImageOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      id: data.id,
      bannerFile: null,
      bannerImageURL: null,
      modalTitle: 'Update Image',
    }))
  }

  const submitImageTop = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    dispatch(updateImageCourse(formVar.id, formVar.bannerFile))

  }
  const filesValid = () => {
    if (!formVar.bannerFile) {
      return "Files is required";
    }
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          bannerImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: null,
        bannerImageURL: null,
      }))
    }
  };

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>

                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Course
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Logo (50px*50px)</th>
                  <th scope='col'>Duration</th>
                  <th scope='col'>Fees</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.courseData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.courseName}</td>
                    <td className='w-10-r'>
                      {item.icon ? <img className='w-2-r h-2-r' src={item.icon} alt="" /> : <img className='w-2-r h-2-r' src={NoImage} alt="" />}
                    </td>
                    <td>{item.duration}</td>
                    <td>{item.fees}</td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>


                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-success action-icon'>
                          <Image onClick={(e) => ImageEditBannerModal(item)} />
                          <div className="tooltipCustom">Update Banner</div>
                        </div>
                        <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Name</Label>
                <Input className="form-control" type="text" placeholder='Enter Course Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, courseName: e.target.value }))} value={formVar.courseName} />
              </Col>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Featured</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select" value={formVar.featured} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, featured: e.target.value }))}>
                  <option value="false">False</option>
                  <option value="true">True</option>

                </Input>
              </Col>
            </Row>
            <Row>
              <Col>
                {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Fees</Label>
                <Input className="form-control" type="text" placeholder='Enter Fees' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, fees: e.target.value }))} value={formVar.fees} />
              </Col>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Duration</Label>
                <Input className="form-control" type="text" placeholder='Enter the Duration' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, duration: e.target.value }))} value={formVar.duration} />
              </Col>
            </Row>
            <Row>
              <Col>
                {submit && feesValid() ? <span className='d-block font-danger'>{feesValid()}</span> : ""}
              </Col>
              <Col>
                {submit && durationValid() ? <span className='d-block font-danger'>{durationValid()}</span> : ""}
              </Col>
            </Row>

            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Level</Label>
                <select className="form-control form-control-inverse " name="select" type="select"
                  value={formVar.levelId} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, levelId: e.target.value }))}>
                  <option value="">Select Level</option>
                  {levelVar?.levelData?.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </Col>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Degree</Label>
                <select className="form-control form-control-inverse " name="select" type="select"
                  value={formVar.degreeId} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, degreeId: e.target.value }))}>
                  <option value="">Select Degree</option>
                  {degreeVar?.degreeData?.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                {submit && levelValid() ? <span className='d-block font-danger'>{levelValid()}</span> : ""}
              </Col>
              <Col>
                {submit && degreeValid() ? <span className='d-block font-danger'>{degreeValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label className="col-form-label" for="recipient-name">University</Label>
                <div id="prefetch">
                  <Typeahead
                    id="multiple-typeahead"
                    labelKey={'name'}
                    multiple
                    options={universityVar?.universitiesData}
                    defaultSelected={formVar.selectedId}
                    onChange={(e) => handleSelect(e)}
                    placeholder="Choose University..."
                  />
                </div>
              </Col>
            </Row>
            <Row>

              <Col>
                {submit && universityValid() ? <span className='d-block font-danger'>{universityValid()}</span> : ""}
              </Col>
            </Row>

            <Label className="col-form-label" for="recipient-name">Description</Label>
            <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, description: e.target.value }))} value={formVar.description} />
            {submit && descriptionValid() ? <span className='d-block font-danger'>{descriptionValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitLevel }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isImageOpenModal} title={formVar.modalTitle} toggler={Imagetoggle} >
        <Form>
          <FormGroup>
            {
              formVar.bannerImageURL && <>
                <div className='d-flex justify-content-center h-10-r'>
                  <img className=' h-100' src={formVar.bannerImageURL} alt="" />
                </div>
              </>
            }
            <Label className="col-form-label" for="recipient-name">Update Image</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>

        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: Imagetoggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImageTop }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default Degree;
