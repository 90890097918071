import React, { Fragment, useEffect, useState, } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, FileText, Image, Trash2 } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import { getUniversities, addUniversityData, ModalToggle, isOpenModal, isImageOpenModal, updateUniversityData, isOpenStatusModal, statusToggle, statusUpdateUniversityStatus, updateImageUniversity, updateBannerUniversity, statusDeleteUniversityStatus } from '../../store/universitiesSlice';
import { fetchCountries } from '../../store/countriesSlice';
import NoImage from '../../assets/images/noimage.png';
import Dropzone from 'react-dropzone-uploader';
import SweetAlert from 'sweetalert2';


const UniversityTable = () => {
  const storeVar = useSelector(state => state.universities)
  console.log(storeVar);
  const countryVar = useSelector(state => state.countries)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const Imagetoggle = () => dispatch(isImageOpenModal());
  const statusModalToggle = () => dispatch(statusToggle());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    countryId: '',
    keyword: '',
    rank: 'ASC',
    name: '',
    desc: '',
    rank: '',
    location: '',
    year: '',
    universityId: '',
    status: 'ACTIVE',
    currentPage: 1,
    modalTitle: null,
    editState: false,
    faqsId: null,
    faqStatus: 'ACTIVE',
    question: '',
    answer: '',
    pageId: '8',
    bannerImageURL: null,
    bannerFile: null,
    id: '',

  });

  useEffect(() => {
    dispatch(getUniversities(formVar.limit, formVar.offset, "[]", formVar.keyword, 'ASC', formVar.status))
    dispatch(fetchCountries('10', '0', 'ACTIVE', ''));
  }, []);


  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    dispatch(getUniversities(formVar.limit, formVar.offset, "[]", e.target.value, 'ASC', formVar.status))

  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getUniversities(formVar.limit, offset, "[]", formVar.keyword, 'ASC', formVar.status))
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getUniversities(formVar.limit, formVar.offset, "[]", formVar.keyword, 'ASC', e.target.value))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      name: data.name,
      desc: data.shortDesc,
      rank: data.rank,
      year: data.year,
      location: data.location,
      countryId: data.country.id,
      universityId: data.id,
      modalTitle: 'Edit University Details'
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    dispatch(fetchCountries('90', '0', 'ACTIVE', ''));
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      question: '',
      answer: '',
      editState: false,
      modalTitle: 'Add New University',
    }))
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      universityId: data.id,
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const submitStatus = () => {
    dispatch(statusUpdateUniversityStatus({ id: formVar.universityId, status: stateStatus }))
  }
  const submitDegree = () => {
    if (titleValid()) {
      setSubmit(true)
      return null
    }
    if (descValid()) {
      setSubmit(true)
      return null
    }
    if (countryValid()) {
      setSubmit(true)
      return null
    }
    if (rankValid()) {
      setSubmit(true)
      return null
    }
    if (locationValid()) {
      setSubmit(true)
      return null
    }
    if (yearValid()) {
      setSubmit(true)
      return null
    }

    setSubmit(false)
    if (formVar.editState) {

      dispatch(updateUniversityData(formVar.universityId, { name: formVar.name, shortDesc: formVar.desc, countryId: formVar.countryId, rank: formVar.rank, location: formVar.location, year: formVar.year }, {limit: formVar.limit, offset: formVar.offset, countryId: "[]", keyword: formVar.keyword, rank: 'ASC', status: formVar.status}))



    } else {
      dispatch(addUniversityData({ name: formVar.name, shortDesc: formVar.desc, countryId: formVar.countryId, rank: formVar.rank, location: formVar.location, year: formVar.year }))
    }
  }
  const titleValid = () => {
    if (!formVar.name) {
      return "University Name is required";
    }
  }
  const descValid = () => {
    if (!formVar.desc) {
      return "Description is required";
    }
  }
  const countryValid = () => {
    if (!formVar.countryId) {
      return "Country is required";
    }
  }
  const rankValid = () => {
    if (!formVar.rank) {
      return "Rank is required";
    }
  }
  const locationValid = () => {
    if (!formVar.location) {
      return "Location is required";
    }
  }
  const yearValid = () => {
    if (!formVar.year) {
      return "Established Year is required";
    }
  }
  const ImageEditBannerModal = (data, imageType) => {
    dispatch(isImageOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      id: data.id,
      type: imageType,
      modalTitle: 'Update Image',
      bannerImageURL: null,
      bannerFile: null,
    }))
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          bannerImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: null,
        bannerImageURL: null,
      }))
    }
  };
  const submitImage = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.type === "logo") {
      dispatch(updateImageUniversity(formVar.id, formVar.bannerFile))
    }
    else if (formVar.type === "banner") {
      dispatch(updateBannerUniversity(formVar.id, formVar.bannerFile))
    }
  }
  const filesValid = () => {
    if (!formVar.bannerFile) {
      return "Files is required";
    }
  }
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeleteUniversityStatus(data.id, 'DELETED'))

        }
      });
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                {/* <Nav tabs className="border-tab"> */}
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
                {/* </Nav> */}
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add New University
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col' className='w-5'>Sl.No</th>
                  <th scope='col' className='w-25'>Name</th>
                  <th scope='col' className='w-25'>Short Description</th>
                  <th scope='col'>Country</th>
                  <th scope='col'>Logo</th>
                  <th scope='col'>Banner</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.universitiesData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name} </td>
                    <td>{item.shortDesc.substring(0, 55)}...</td>
                    <td>{item.country?.name}</td>
                    <td className='w-10-r'>
                      {item.image ? <img className='w-8-r h-3-r' src={item.image} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
                    </td>
                    <td className='w-10-r'>
                      {item.uniBanner ? <img className='w-8-r h-3-r' src={item.uniBanner} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
                    </td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-primary action-icon'>
                          <Image onClick={(e) => ImageEditBannerModal(item, 'logo')} />
                          <div className="tooltipCustom">Update Logo</div>
                        </div>
                        <div className='cursor-pointer font-secondary action-icon'>
                          <Image onClick={(e) => ImageEditBannerModal(item, 'banner')} />
                          <div className="tooltipCustom">Update Banner</div>
                        </div>
                        <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar?.universitiesData &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalUniversity}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Name</Label>
            <Input className="form-control" type="text" placeholder='Enter university name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
            {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Short Description</Label>
            <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
            {submit && descValid() ? <span className='d-block font-danger'>{descValid()}</span> : ""}

            <Row>
              <Col className="" xs="6">
                <Label className="col-form-label" for="recipient-name">Country</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.countryId} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, countryId: e.target.value }))}>
                  <option value="" >Select Country</option>
                  {countryVar.countriesData?.map((item, index) => (
                    <option value={item.id} key={index}>{item.name}</option>
                  ))}
                </Input>

                {submit && countryValid() ? <span className='d-block font-danger'>{countryValid()}</span> : ""}
              </Col>
              <Col className="" xs="6">
                <Label className="col-form-label" for="recipient-name">QS Rank</Label>
                <Input className="form-control" type="text" placeholder="Enter QS Rank" onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, rank: e.target.value }))} value={formVar.rank} />
                {submit && rankValid() ? <span className='d-block font-danger'>{rankValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Col className="" xs="6">
                <Label className="col-form-label" for="recipient-name">Location</Label>
                <Input className="form-control" type="text" placeholder='Enter location' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, location: e.target.value }))} value={formVar.location} />
                {submit && locationValid() ? <span className='d-block font-danger'>{locationValid()}</span> : ""}
              </Col>
              <Col className="" xs="6">
                <Label className="col-form-label" for="recipient-name">Established in</Label>
                <Input className="form-control" type="text" placeholder='Enter established year' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, year: e.target.value }))} value={formVar.year} />
                {submit && yearValid() ? <span className='d-block font-danger'>{yearValid()}</span> : ""}
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isImageOpenModal} title={formVar.modalTitle} toggler={Imagetoggle} >
        <Form>
          <FormGroup>
            {
              formVar.bannerImageURL && <>
                <div className='d-flex justify-content-center h-10-r'>
                  <img className=' h-100' src={formVar.bannerImageURL} alt="" />
                </div>
              </>
            }
            <Label className="col-form-label" for="recipient-name">Update Image</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>

        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: Imagetoggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImage }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default UniversityTable;
