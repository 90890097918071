
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,
  testDetailsData: null,
};

export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    settest(state, { payload }) {
      state.testData = payload;
      state.testsCount = payload.total;
    },
    updatetestData(state, { payload }) {
      const objIndex = state.testData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.testData[objIndex] = payload
      }
    },
    setTestSlider(state, { payload }) {
      state.testDataSlider = payload.result;
      state.testsCountSlider = payload.count;
    },
    updateTestSliderData(state, { payload }) {
      const objIndex = state.testDataSlider.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.testDataSlider[objIndex] = payload
      }
    },
    addTestSliderData(state, { payload }) {
      state.testDataSlider.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    }, 
    isOpenDetailModal(state, { payload }) {
      state.isOpenDetailModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    ModalDetailsToggle(state, { payload }) {
      state.isOpenDetailModal = !state.isOpenDetailModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    },
    setTestDetails(state, { payload }) {
      state.testDetailsData = payload;
    },
    setClearState(state, { payload }) {
      state.error = ''
      state.isOpenModal = false
      state.isStatusOpenModal = false
      state.isImageOpenModal = false
      state.testDetailsData = null
    },
  },
});

export const { settest, setTestSlider, addTestSliderData, updatetestData, updateTestSliderData,  isOpenModal, isOpenDetailModal, isImageOpenModal, ModalToggle, ModalDetailsToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle,
  setTestDetails, setClearState } = testSlice.actions;
export default testSlice.reducer;

export function fetchTests(limit, offset, status, keyword) {
  return async function fetchTestsThunk(dispatch, getState) {
    try {
      await service.getTests(limit, offset, status, keyword).then(
        (response) => {
          dispatch(settest(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}




export function addTest(data) {
  return async function addtestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.createtest(data).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(updatetestData(response.data))
          dispatch(setLoading(false))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateTest(id, payload) {
  return async function updateTestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatetestData(id, payload).then(
        (response) => {
          dispatch(updatetestData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageTest(id, file) {
  return async function updateImageTestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateTestImage(id, file).then(
        (response) => {
          dispatch(updatetestData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateTestStatus(id, status) {
  return async function statusUpdateTestStatusThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateTestStatus(id, status).then(
        (response) => {
          dispatch(updatetestData(response.data))
          dispatch(statusToggle())
          dispatch(setLoading(false))
          successHandler('Status Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeletetestStatus(id, status) {
  return async function statusDeletetestThunk(dispatch) {
    try {
      // dispatch(setLoading(true))
      await service.updateTestStatus(id, status).then(
        (response) => {
          dispatch(updatetestData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



export function fetchTestsSliders(id, limit, offset, status, keyword) {
  return async function fetchTestSliderThunk(dispatch, getState) {
    try {
      await service.getTestSlider(id, limit, offset, status, keyword).then(
        (response) => {
          dispatch(setTestSlider(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function addTestSliders(data) {
  return async function addtestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.createtestSliders(data).then(
        (response) => {
          dispatch(addTestSliderData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function updateTestSliders(id, payload) {
  return async function updateTestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatetestSliderData(id, payload).then(
        (response) => {
          dispatch(updateTestSliderData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateExamSliderStatus(id, status) {
  return async function statusUpdatetestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateExamSlider(id, status).then(
        (response) => {
          dispatch(updateTestSliderData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



export function updateImageTestSlider(id, file) {
  return async function updateBlogsImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateTestSliderImage(id, file).then(
        (response) => {
          dispatch(updateTestSliderData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeletetestSlider(id, status) {
  return async function statusDeletetestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateExamSlider(id, status).then(
        (response) => {
          dispatch(updateTestSliderData(response.data))
          dispatch(setLoading(false))
          // dispatch(statusToggle())
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          // errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function fetchTestsDetails(id) {
  return async function fetchTestsThunk(dispatch, getState) {
    try {
      await service.getTestsDetails(id).then(
        (response) => {
          dispatch(setTestDetails(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function updateTestDetails(id, data) {
  return async function addtestDetailThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateTestDetails(id, data).then(
        (response) => {
          dispatch(setTestDetails(response.data))
          dispatch(setLoading(false))
          dispatch(ModalDetailsToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}