import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WhyUsPageModel from '../../Components/WhyUs/WhyUs';
import { fetchwhyUs } from '../../store/whyUsSlice';


const AboutUs = () => {
  const dispatch = useDispatch();
  const storeVar = useSelector(state => state.whyUs);
  useEffect(() => {
    dispatch(fetchwhyUs());
  }, []);

  return (
    <Fragment>
      <WhyUsPageModel props={storeVar?.whyUsData
} />
    </Fragment>
  );
};

export default AboutUs;
