import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H5, H6 } from '../../../AbstractElements';
import RadialChart from './RadialChart';
import { dispatched, twitter, returnbox, product, neworder, category, freshLeeds } from '../../../Data/svgIcons';

const SocialWidget = (props) => {
  const num = props.increment ? props.increment : 0
  const chart = {
    color: [props.color],
    series: [num]
  };

  return (
    <Card className='social-widget widget-hover'>
      <CardBody>
        {
          !props.staff && (
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <div className='social-icons d-flex align-items-center justify-content-center'>
                  {props.title === 'Active Leads' && (<>{category}</>)}
                  {props.title === 'Fresh Leads' && (<>{freshLeeds}</>)}
                  {props.title === 'Follow Up Today' && (<>{dispatched}</>)}
                  {props.title === 'Follow Up Tomorrow' && (<>{returnbox}</>)}
                  {props.title === 'Follow Up Later' && (<>{product}</>)}
                  {/* {props.title === 'Status' && (<>{neworder}</>)} */}
                </div>
              </div>
            </div>
          )
        }
        {
          props.staff && (
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>{props.data}</H5>
            </div>
          )
        }
        <div className='pt-2'>
          <H6>{props.title}</H6>
        </div>
        <div className='social-content'>
          {
            !props.staff && (
              <div>
                <H5 attrH5={{ className: 'mb-1' }}>{props.data}</H5>
              </div>
            )
          }
          {/* <div className='social-chart'>
            <RadialChart chartData={chart} />
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default SocialWidget;
