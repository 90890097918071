import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import FaqsTable from './DataCounter';
const DataCounter = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Data Counter' mainTitle='Data Counter' />
      <Container fluid={true}>
        <Row>
          <FaqsTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DataCounter;
