
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const scholarshipSlice = createSlice({
  name: 'scholarship',
  initialState,
  reducers: {
    setscholarship(state, { payload }) {
        state.scholarshipData = payload.result;
        state.scholarshipsCount = payload.total;
      },
    updatescholarshipData(state, { payload }) {
      const objIndex = state.scholarshipData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.scholarshipData[objIndex] = payload
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setscholarship,  updatescholarshipData, DeleteBrandsData, isOpenModal, isImageOpenModal, ModalToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle } = scholarshipSlice.actions;
export default scholarshipSlice.reducer;

export function fetchscholarship(limit, offset, status, keyword) {
  return async function fetchscholarshipThunk(dispatch, getState) {
    try {
      await service.getscholarship(limit, offset, status,  keyword).then(
        (response) => {
          dispatch(setscholarship(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}
export function addScholarship(data) {
  return async function addscholarshipThunk(dispatch) {
    try { 
      dispatch(setLoading(true))
      await service.createscholarship(data).then(
        (response) => {
          dispatch(updatescholarshipData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateScholarship(id, payload) {
  return async function updatescholarshipThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatescholarshipdata(id, payload).then(
        (response) => {
          // dispatch(updatescholarshipData(response.data))
          dispatch(fetchscholarship(10, 0, 'ACTIVE', ''));
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



export function statusUpdateScholarshipStatus(payload) {
  return async function statusUpdateBlogsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdatescholarship(payload.id, payload.status).then(
        (response) => {
          dispatch(updatescholarshipData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeleteScholarshipStatus(id, status) {
  return async function statusDeleteScholarshipThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdatescholarship(id, status).then(
        (response) => {
          dispatch(updatescholarshipData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

