import React, { Fragment, useEffect, useState,  } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, Trash2  } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { fetchwhyUsPerks, isOpenModal, ModalToggle, addwhyUsPerksData, updateWhyUsPerksData, statusDeleteWhyUsPerksStatus } from '../../store/whyUsSlice';
import SweetAlert from 'sweetalert2';


const FaqsTable = () => {
  const storeVar = useSelector(state => state.whyUs)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    title: '',
    desc: '',
    perksId: '',
  });

  useEffect(() => {
    dispatch(fetchwhyUsPerks())
  }, []);

  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      perksId: data.id,
      title: data.title,
      desc: data.desc,
      modalTitle: 'Edit Perks'
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      title: '',
      desc: '',
      editState: false,
      modalTitle: 'Add Perks',
    }))
  }

  const submitDegree = () => {
    if (titleValid()) {
      setSubmit(true)
      return null
    }
    if (descValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    
    
    
    if (formVar.editState) {
      dispatch(updateWhyUsPerksData(formVar.perksId, { title: formVar.title, desc: formVar.desc}))
    } else {
      dispatch(addwhyUsPerksData({ title: formVar.title, desc: formVar.desc}))
    }
  }


  const titleValid = () => {
    if (!formVar.title) {
      return "Title is required";
    }
  }
  const descValid = () => {
    if (!formVar.desc) {
      return "Description is required";
    }
  }

  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeleteWhyUsPerksStatus(data.id, 'DELETED'))


        }
      });
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
              </Col>
              <Col md="4">
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Perks
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Title</th>
                  <th scope='col'>Description</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.whyUsPerksData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.title} </td>
                    <td>{item.desc}</td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        {/* <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div> */}
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {/* {
          storeVar.faqsData.length>0 &&
        <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalFaq}
          itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        } */}
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
              <Label className="col-form-label" for="recipient-name">Title</Label>
              <Input className="form-control" type="text" placeholder='Enter Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar.title} />
              {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Description</Label>
            <textarea className='form-control' name='description' rows='5' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
            {submit && descValid() ? <span className='d-block font-danger'>{descValid()}</span> : ""}
            </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default FaqsTable;
