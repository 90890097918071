import React from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { P } from '../../AbstractElements';

const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear();
  }
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              {/* <P attrPara={{ className: "mb-0" }}>Copyright to STUDYABROAD 2024 <a href="https://www.webappssoft.com"/>' © Developed By WASS.</P> */}
              <div className="content">
              Copyright to  <a href="/">&nbsp;WAY2STUDYABROAD&nbsp;</a> Ⓒ 2024  All rights reserved | Maintained by <a href="https://www.webappssoft.com">&nbsp;WASS</a>
                    </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;