import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  universitiesData: [],
  isOpenModal: false,
  isImageOpenModal: false,
  isStatusOpenModal: false,
  totalUniversity: 0,
}

export const universitiesSlice = createSlice({
  name: "universities",
  initialState,
  reducers: {
    setUniversitiesData(state, { payload }) {
      state.universitiesData = payload.result
      state.totalUniversity = payload.count
    },
    pushUniversity(state, { payload }) {
      state.universitiesData.push(payload)
    },
    updateUniversitysData(state, { payload }) {
      const objIndex = state.universitiesData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.universitiesData[objIndex] = payload
      }
    },
    updateStatusData(state, { payload }) {
      const objIndex = state.universitiesData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.universitiesData[objIndex].status = payload.status
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
  }
})

export const { setUniversitiesData, pushUniversity, updateUniversitysData, updateStatusData, isOpenModal, ModalToggle, statusModalToggle, isOpenStatusModal, statusToggle, isImageOpenModal, ImagestatusToggle } = universitiesSlice.actions;

export default universitiesSlice.reducer;

export function getUniversities(limit, offset, countryid, keyword, rank, status) {
  return async function getUniversitiesThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getUniversity(limit, offset, countryid, keyword, rank, status).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setUniversitiesData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addUniversityData(payload) {
  return async function addUniversityThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addUniversity(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(pushUniversity(response.data))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateUniversityData(id, payload, data) {
  return async function updateUniversityThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateUniversity(id, payload).then(
        (response) => {
          // dispatch(updateUniversitysData(response.data))
          dispatch(getUniversities(data.limit, data.offset, "[]", data.keyword, data.rank, data.status))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function statusUpdateUniversityStatus(payload) {
  return async function statusUpdateFaqsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateUniversity(payload.id, payload.status).then(
        (response) => {
          dispatch(updateStatusData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeleteUniversityStatus(id, status) {
  return async function statusUpdateFaqsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateUniversity(id, status).then(
        (response) => {
          dispatch(updateStatusData(response.data))
          dispatch(setLoading(false))

          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageUniversity(id, file) {

  return async function updateImageUniversityThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateUniversityImage(id, file).then(
        (response) => {
          dispatch(updateUniversitysData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateBannerUniversity(id, file) {
  return async function updateBannerUniversityThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateUniversityBanner(id, file).then(
        (response) => {
          dispatch(updateUniversitysData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
