
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const whyUsSlice = createSlice({
  name: 'whyUs',
  initialState,
  reducers: {
    setwhyUss(state, { payload }) {
      state.whyUsData = payload;
    },
    setwhyUssPerks(state, { payload }) {
      state.whyUsPerksData = payload;
    },
    pushWhyUs(state, { payload }) {
      state.whyUsData.push(payload)
    },
    pushWhyUsPerks(state, { payload }) {
      state.whyUsPerksData.push(payload)
    },
    updatewhyUssData(state, { payload }) {
      const objIndex = state.whyUsData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.whyUsData[objIndex] = payload
      }
    },
    updatewhyUsPerksData(state, { payload }) {
      const objIndex = state.whyUsPerksData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.whyUsPerksData[objIndex] = payload
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setwhyUss, setwhyUssPerks, pushWhyUsPerks, updatewhyUssData, updatewhyUsPerksData, pushWhyUs, DeleteBrandsData, isOpenModal, isImageOpenModal, ModalToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle } = whyUsSlice.actions;
export default whyUsSlice.reducer;

export function fetchwhyUs() {
  return async function fetchwhyUssThunk(dispatch, getState) {
    try {
      await service.getwhyUsdata().then(
        (response) => {
          dispatch(setwhyUss(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function addwhyUsPerksData(payload) {
  return async function addwhyUsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addwhyUsPerks(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(pushWhyUsPerks(response.data))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatewhyUs(payload) {
  return async function updatewhyUssThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatewhyUsdata(payload).then(
        (response) => {
          dispatch(setwhyUss(response.data));
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function fetchwhyUsPerks() {
  return async function fetchwhyUssThunk(dispatch, getState) {
    try {
      await service.getwhyUsPerksdata().then(
        (response) => {
          dispatch(setwhyUssPerks(response.data.result));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}


export function updateWhyUsPerksData(id, payload) {
  return async function updateUswhyUsPerksThunk(dispatch, getState) {
    try {
      await service.updateWhyUsPerksdata(id, payload).then(
        (response) => {
          dispatch(updatewhyUsPerksData(response.data));
          dispatch(ModalToggle())

        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}



export function statusDeleteWhyUsPerksStatus(id, status) {
  return async function statusUpdateWhyusPerksThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateWhyUsPerks(id, status).then(
        (response) => {
          dispatch(updatewhyUsPerksData(response.data));
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          // errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}