import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: "",
  dashboardData: "",
  dashboardpieData: "",
  activeLeeds: 0,
  followUpLater: 0,
  followUpToday: 0,
  followUpTomorrow: 0,
  freshLeeds: 0,
  dashboardStaffData: [],
  allTodos: [],
  // allTodos: [
  //   {
  //     date: "10 Nov",
  //     id: 0,
  //     status: "pending",
  //     statusCode: "primary",
  //     title: "Check validation involves making sure all your tags are properly closed and nested.",
  //   },

  //   {
  //     date: "04 Aug",
  //     id: 1,
  //     status: "pending",
  //     statusCode: "danger",
  //     title: "Test the outgoing links from all the pages to the specific domain under test.",
  //   },

  //   {
  //     date: "25 Feb",
  //     id: 2,
  //     status: "pending",
  //     statusCode: "success",
  //     title: "Test links are used to send emails to admin or other users from web pages.",
  //   },

  //   {
  //     date: "15 Dec",
  //     id: 3,
  //     status: "pending",
  //     statusCode: "primary",
  //     title: "Options to create forms, if any, form deletes a view or modify the forms.",
  //   },

  //   {
  //     date: "11 Nov",
  //     id: 4,
  //     status: "pending",
  //     statusCode: "danger",
  //     title: "Wrong inputs in the forms to the fields in the forms.",
  //   },

  //   {
  //     date: "04 Sept",
  //     id: 5,
  //     status: "pending",
  //     statusCode: "danger",
  //     title: "Check if the instructions provided are perfect to satisfy its purpose.",
  //   },
  //   {
  //     date: "08 July",
  //     id: 6,
  //     status: "pending",
  //     statusCode: "success",
  //     title: "Application server and Database server interface."
  //   }
  // ]
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getdashboard(state, { payload }) {
      state.dashboardData = payload;
      state.activeLeeds = payload.activeLeeds
      state.followUpLater = payload.followUpLater
      state.followUpToday = payload.followUpToday
      state.followUpTomorrow = payload.followUpTomorrow
      state.freshLeeds = payload.freshLeeds
    },
    setdashboardStaff(state, { payload }) {
      state.dashboardStaffData = payload;
    },
    setTodoList(state, { payload }) {
      state.allTodos = payload;
    },
    pushTodoList(state, { payload }) {
      state.allTodos.unshift(payload);
    },
    getpiedashboard(state, { payload }) {
      state.dashboardpieData = payload;
    },
    deleteTodo(state, { payload }) {
      const objIndex = state.allTodos.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.allTodos.splice(objIndex, 1)
      }
    },
    updateTodoStatus(state, { payload }) {
      const objIndex = state.allTodos.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.allTodos[objIndex].status = payload.status
      }
    },
  }
})


export const { getdashboard, getpiedashboard, updateTodoStatus, setdashboardStaff, setTodoList,
  pushTodoList, deleteTodo } = dashboardSlice.actions;
export default dashboardSlice.reducer;

/*Get dashboards Data */
export function dashboardsData() {
  return async function dashboardsDataThunk(dispatch, getState) {
    dispatch(setLoading(true))
    try {
      await service.dashboard().then(
        (response) => {
          if (response.data) {
            dispatch(getdashboard(response.data))
            dispatch(setLoading(false))
          }
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function dashboardsStaffData() {
  return async function dashboardsDataThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.dashboardsStaff().then(
        (response) => {
          if (response.data) {
            dispatch(setdashboardStaff(response.data))
            dispatch(setLoading(false))
          }
        }, (error) => {
          dispatch(setLoading(false))

        }
      );
    } catch (err) {

    }
  }
}

export function dashboardpie(dashboardId) {
  return async function dashboardpieThunk(dispatch, getState) {
    // dispatch(setLoading(true))
    try {
      await service.dashboardpie(dashboardId).then(
        (response) => {
          if (response.data) {
            dispatch(getpiedashboard(response.data))
            dispatch(setLoading(false))
          }
        }, (error) => {

        }
      );
    } catch (err) {

    }
  }
}

export function getTodoList() {
  return async function getTodoListThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getTodoList().then(
        (response) => {
          if (response.data) {
            dispatch(setTodoList(response.data))
            dispatch(setLoading(false))
          }
        }, (error) => {

        }
      );
    } catch (err) {

    }
  }
}

export function addTodoList(desc) {
  return async function addTodoListThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addTodoList(desc).then(
        (response) => {
          if (response.data) {
            dispatch(pushTodoList(response.data))
            dispatch(setLoading(false))
            successHandler('Task Added Successfully')
          }
        }, (error) => {

        }
      );
    } catch (err) {

    }
  }
}

export function todoStatusUpdate(id, status) {
  return async function todoStatusUpdateThunk(dispatch) {
    // dispatch(setLoading(true))
    try {
      await service.todoStatusUpdate(id, status).then(
        (response) => {
          if (response.data) {
            dispatch(updateTodoStatus({ id, status }))
            // dispatch(setLoading(false))
            if(status==='PENDING'){
              errorHandler({status:300,data:{message:'Task In-completed !'}})
            }else if(status==='COMPLETE'){
              successHandler('Task Completed !')
            }
          }
        }, (error) => {

        }
      );
    } catch (err) {

    }
  }
}

export function todoDelete(id,status) {
  return async function todoDeleteThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.todoStatusUpdate(id, status).then(
        (response) => {
          if (response.data) {
            dispatch(deleteTodo({ id }))
            dispatch(setLoading(false))
            successHandler('Task Deleted Successfully')
          }
        }, (error) => {

        }
      );
    } catch (err) {

    }
  }
}