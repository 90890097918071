import React, { useState, Fragment, useContext } from 'react';
import { toast } from 'react-toastify';

// import TodoContext from '../../../_helper/Todo';
import { H4, H5, LI, UL } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { todoDelete, todoStatusUpdate } from '../../store/dashboardSlice';

const TodoList = () => {
  // const { removeItems, selectedItem } = useContext(TodoContext);
  const storeVar = useSelector(state => state.dashboard)
  const dispatch = useDispatch();

  function statusChange(id, status) {
    dispatch(todoStatusUpdate(id,status))
  }

  const handleRemoveTodo = (todoId) => {
    dispatch(todoDelete(todoId, 'DELETED'))
  };
  const handleMarkedTodo = (itemId, status) => {
    if (status === 'COMPLETE') {
      statusChange(itemId, 'PENDING');
    } else if (status === 'PENDING') {
      statusChange(itemId, 'COMPLETE');
    }
  };

  return (
    <Fragment>
      <div className='todo-list-body'>
        <UL attrUL={{ className: 'simple-list', id: 'todo-list' }}>
          {storeVar.allTodos.length > 0
            ? storeVar.allTodos.map((todo, index) => (
              <LI attrLI={{ className: 'task border-0 ' + ' ' + todo.status }} key={index}>
                <div className='task-container'>
                  <H4 attrH4={{ className: 'task-label f-14' }}>{todo.desc}</H4>
                  <div className='d-flex align-items-center gap-4'>
                    {
                      todo.status === 'PENDING' && (
                        <span className={`badge badge-light-danger`}>{todo.status}</span>
                      )
                    }
                    {
                      todo.status === 'COMPLETE' && (
                        <span className={`badge badge-light-success`}>{todo.status}</span>
                      )
                    }
                    {
                      todo.status === 'In Progress' && (
                        <span className={`badge badge-light-primary`}>{todo.status}</span>
                      )
                    }

                    <H5 attrH5={{ className: 'assign-name m-0' }}>{todo.date}</H5>
                    <span className='task-action-btn'>
                      <span className='action-box large delete-btn' title='Delete Task' onClick={() => handleRemoveTodo(todo.id)}>
                        <i className='icon'>
                          {/* <i className='icon-trash'></i> */}
                          <i className="fa fa-trash-o"></i>
                        </i>
                      </span>
                      <span className='action-box large complete-btn' title='Mark Complete' onClick={() => handleMarkedTodo(todo.id, todo.status)}>
                        <i className='icon'>
                          {/* <i className='icon-check'></i> */}
                          <i className="fa fa-check-square-o"></i>
                        </i>
                      </span>
                    </span>
                  </div>
                </div>
              </LI>
            ))
            : ''}
        </UL>
      </div>
    </Fragment>
  );
};
export default TodoList;
