import React, { useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, FormGroup, Label, Input, TabContent, TabPane, Row, Table, ModalFooter } from 'reactstrap';
// import { Contact, Hometxt, PillTabs, Profile } from '../../../../../../Constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Trash2, Edit, } from "react-feather"
import { H6, H5, P, Btn } from '../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import { useDispatch, useSelector } from 'react-redux';
import { addReminders, deleteReminder, getActivityLog, getreminder, updateReminder } from '../../store/remindersSlice';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import { getStaffList } from '../../store/staffSlice';
import { updateImageleads } from '../../store/leadsSlice';
// import { Row } from 'react-bootstrap';
// import HeaderCard from '../../../../../Common/Component/HeaderCard';

const PillTab = (props) => {

  const [pillTab, setpillTab] = useState('1');
  // const [leadData, setpillTab] = useState('1');

  const storeVar = useSelector(state => state.reminder)
  const staffVar = useSelector(state => state.staff)
  const dispatch = useDispatch();
  const multiple = false;
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    submit: false,
    limit: 10,
    offset: 0,
    roles: 'STAFF',
    keyword: '',
    editState: false,
    staffId: null,
    staff: null,
    remindDateTime: '',
    desc: '',
    staffName: '',
    remainderId: '',
    bannerFile: null,
    bannerImageURL: null,
  });

  const changePillTab = (pill) => {
    props.editBtnAdd(pill)
    setpillTab(pill)
    if (pill === '3') {
      dispatch(getreminder(formVar.limit, formVar.offset, props.leadData?.brandId))
      dispatch(getStaffList(formVar.limit, formVar.offset, 'ACTIVE', formVar.roles, formVar.keyword))
    } else if (pill === '4') {
      dispatch(getActivityLog(props.leadData?.brandId))
    }
  }

  function getDateTime(date) {
    if (date) {
      return moment(date).format('MMM Do YYYY hh:mm A')
    } else {
      return '-'
    }
  }
  const handleSelect = (e) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      staffId: e[0]?.id,
      staffName: e[0]?.staffName,
      staff: e[0],
    }))
  }

  function addReminder() {
    if (dateTimeValid() || staffValid() ) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    if (!formVar.editState) {
      dispatch(addReminders({
        staffId: formVar.staffId,
        enquiryId: [props.leadData?.brandId],
        remindDateTime: formVar.remindDateTime,
        desc: formVar.desc
      }, formVar.staffName))
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        staff: '',
        desc: '',
      }));
    } else {
      dispatch(updateReminder({
        id: formVar.remainderId,
        staffId: formVar.staffId,
        remindDateTime: formVar.remindDateTime,
        desc: formVar.desc
      }, formVar.staffName))
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        editState: false,
      }));
    }
  }
  function removeReminder() {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      remindDateTime: '',
      desc: '',
      remainderId: null,
      staff: null,
    }))
  }
  function openEdit(data) {
    if (data) {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        editState: true,
        remindDateTime: moment(data.remindDateTime).format('YYYY-MM-DDThh:mm:ss'),
        desc: data.desc,
        remainderId: data.id,
        staff: data.staff,
        staffId: data.staff?.id,
        staffName: data.staff?.staffName
      }))
    }
  }

  const reminderDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deleteReminder(data.id))
        }
      });
  }
  const dateTimeValid = () => {
    if (!formVar.remindDateTime) {
      return "Date Time is required";
    }
  }
  const staffValid = () => {
    if (!formVar.staff) {
      return "Staff is required";
    }
  }
// called every time a file's `status` changes
const handleChangeStatus = ({ meta, file }, status) => {
  if (status === 'done') {
    const reader = new FileReader();
    reader.onload = (e) => {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerImageURL: e.target.result,
      }))
    };
    reader.readAsDataURL(file);
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      bannerFile: file,
    }))
  } else if (status === "removed") {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      bannerFile: null,
      bannerImageURL: null,
    }))
  }
};
  const submitAttachments = () => {
    // console.log(props.leadData.brandId, formVar.bannerFile);
    dispatch(updateImageleads(props.leadData.brandId, formVar.bannerFile ))
  }
  return (
    <Col sm='12' className='xl-100 box-col-12'>
      <Card>
        <CardBody style={{ padding: '0px' }}>
          <Nav className='nav-pills'>
            <NavItem>
              <NavLink href='#' className={pillTab === '1' ? 'active' : ''} onClick={() => changePillTab('1')}>
                Profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#' className={pillTab === '2' ? 'active' : ''} onClick={() => changePillTab('2')}>
                Attachments
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#' className={pillTab === '3' ? 'active' : ''} onClick={() => changePillTab('3')}>
                Follow Up
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#' className={pillTab === '4' ? 'active' : ''} onClick={() => changePillTab('4')}>
                Activity Log
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={pillTab}>
            <TabPane className='fade show' tabId='1'>
              <Row className='' md="2" xl="3">
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Name</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.name ? props.leadData.name : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Email</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.email ? props.leadData.email : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Mobile No</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.mobile ? props.leadData.mobile : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Alternate Mobile No</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.altmobile ? props.leadData.altmobile : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Enquiry For</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.enquiryfor ? props.leadData.enquiryfor : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Status</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.statusfor ? props.leadData.statusfor : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Query Messege</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.query ? props.leadData.query : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Assigned To</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData?.staffName ? props.leadData?.staffName : '-'}</H5>
                </div>
                <div className='row py-3 p-b-0'>
                  <H6 attrH6={{ className: "opacity-50" }} >Date Of Lead</H6> <H5 attrH5={{ className: "fw-normal" }}>{props.leadData.dateRegistered ? props.leadData.dateRegistered : '-'}</H5>
                </div>
              </Row>
            </TabPane>
            <TabPane tabId='2'>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">Upload documents(only image file)</Label>
                <Dropzone
                  className='dropzone dz-clickable'
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  // canCancel={false}
                  accept="image/*"
                  inputContent='Browse or Drop a file'
                  styles={{
                    dropzone: { width: '100%', height: 200 },
                    dropzoneActive: { borderColor: 'green' },
                  }}
                />
                {/* {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""} */}
              </FormGroup>
              <ModalFooter>
                <Btn attrBtn={{ color: 'primary', onClick: submitAttachments }}>Save Changes</Btn>
              </ModalFooter>
              {props.leadData.enquiryDoc.map((item, index) => (
              <div className='row py-3 p-b-0'>
              <img src={item.document}/>
              </div>
              ))}
              
              
            </TabPane>
            <TabPane tabId='3' className='px-3'>
              <Row className='py-3'>
                <Col sm='12' lg='3'>
                  <Label className="col-form-label" for="date-time">Select Date & Time</Label>
                  <Input type="datetime-local" placeholder="Select date" onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, remindDateTime: e.target.value }))} value={formVar.remindDateTime} />
                </Col>

                <Col sm='12' lg='3'>
                  <Label className="col-form-label" for="select-staff">Set Follow Up To</Label>
                  <div>
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="staffName"
                      selected={formVar.staff ? [formVar.staff] : []}
                      multiple={multiple}
                      options={staffVar?.staffList}
                      onChange={(e) => handleSelect(e)}
                      placeholder="Choose a staff..."
                    />
                  </div>
                </Col>

                <Col sm='12' lg='4'>
                  <Label className="col-form-label" for="select-staff">Description</Label>
                  <Input type="text" placeholder="Add Note" onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
                </Col>

                <Col sm='12' lg='2' className='p-b-4 d-flex align-items-end gap-1'>
                  <Btn attrBtn={{ color: 'primary', onClick: addReminder }} >{formVar.editState ? 'Update' : 'Set Follow Up'}</Btn>
                  {
                    formVar.editState && (
                      <Btn attrBtn={{ color: 'danger', onClick: removeReminder }} >X</Btn>
                    )
                  }
                </Col>
              </Row>

              <Row className='py-3'>
                <Table hover={true} className='table-border-horizontal table-light'>
                  <thead>
                    <tr>
                      <th scope='col'>Date</th>
                      <th scope='col'>Set Reminder To</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeVar?.reminderData?.map((item, index) => (
                      <tr key={index}>
                        <td>{getDateTime(item.remindDateTime)}</td>
                        <td>{item.staff?.staffName}</td>
                        <td>{item.desc}</td>
                        <td>
                          <div className='d-flex gap-2'>
                            <div className='cursor-pointer font-primary action-icon' onClick={(e) => openEdit(item)}>
                              <Edit />
                              <div className="tooltipCustom">Edit</div>
                            </div>
                            <div className='cursor-pointer font-danger action-icon' onClick={(e) => reminderDelete(item)}>
                              <Trash2 />
                              <div className="tooltipCustom">Delete</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </TabPane>
            <TabPane tabId='4' className='p-3'>
              <Row className='py-2'>
                {/* <textarea className='form-control' placeholder='Enter Activity'></textarea>
                <Col sm='12' lg='12' className='d-flex justify-content-end px-0 py-2'>
                  <Btn attrBtn={{ color: 'primary', }} >Save</Btn>
                </Col> */}
              </Row>
              <Row>
                {/* <div> */}
                {
                  storeVar?.activityLogData?.map((item, index) => (
                    <>
                      <div className='w-full border p-3' key={index}>
                        <P>{getDateTime(item.timestamp)} - <span className='fw-bold opacity-75'>{item.staff?.staffName}</span></P>
                        <P>{item.details}</P>
                      </div>
                    </>
                  ))}
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PillTab;
