import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, FileText, Eye, Download } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
// import Dropzone from 'react-dropzone-uploader';
// import NoImage from '../../assets/images/noimage.png';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import { fetchlead, addlead, updatelead, statusToggle, statusUpdateLeadStatus, statusDeleteleadstatus, isOpenModal, ModalToggle, isOpenStatusModal, isImageOpenModal, ImagestatusToggle, ModalAsignToggle, isOpenAsignModal, asignStaff } from '../../store/leadsSlice';
import Pagination from '../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';
// import { CSVLink, CSVDownload } from "react-csv";
import PillTab from '../../Components/PillTab/index';
import Checkbox from '../../Components/ChackBoxCell/ChackBox';
import { getStaffList } from '../../store/staffSlice';
import { errorHandler } from '../../shared/_helper/responseHelper';

const LeadsTable = () => {

  const storeVar = useSelector(state => state.leads)
  const staffVar = useSelector(state => state.staff)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const toggleAsign = () => dispatch(ModalAsignToggle());
  const toggleView = () => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      viewState: false,
    }))
  };
  const multiple = false;
  const Imagetoggle = () => dispatch(ImagestatusToggle());
  const statusModalToggle = () => dispatch(statusToggle());

  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [editButtonAdd, setEditButtonAdd] = useState('1');
  const [submit, setSubmit] = useState(false);
  const [items, setItems] = useState();


    const [typingTimer, setTypingTimer] = useState(null);
    const typingDelay = 800;

  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    roles: 'STAFF',
    keyword: '',
    currentPage: 1,
    status: 'NEW',
    modalTitle: null,
    editState: false,
    cityId: null,
    brandId: null,
    brandName: '',
    bannerFile: null,
    bannerImageURL: null,
    name: '',
    email: '',
    mobile: '',
    altmobile: '',
    enquiryfor: '',
    query: '',
    statusfor: 'NEW',
    startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    viewState: false,
    leadIndex: '',
    checkedRows: [],
    idArray: [],
    asignStaffId: null,
    dateRegistered: '',
    staffName: '',
    enquiryDoc:'',
  });

  useEffect(() => {
    dispatch(fetchlead(formVar.limit, formVar.offset, formVar.status, formVar.keyword, formVar.startDate, formVar.endDate));
  }, []);

  // const openModal = (item) => {
  //   setdesc(item.desc);
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const pageChange = (page) => {
    const offset = page - 1;
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(fetchlead(formVar.limit, offset, formVar.status, formVar.keyword, formVar.startDate, formVar.endDate))
  };

  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(fetchlead(formVar.limit, formVar.offset, formVar.status, keyword, formVar.startDate, formVar.endDate))
    }, typingDelay);
    setTypingTimer(timer);
  };

  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: false
    })
      .then((result) => {
        if (result.value) {

          dispatch(statusDeleteleadstatus(data.id, 'DELETED'))

        }
      });
  }

const setLimit=(e)=>{
let limit=parseInt(e)
setFormVar((prevFormVar) => ({ ...prevFormVar, limit: limit }))
dispatch(fetchlead(limit, formVar.offset, formVar.status, formVar.keyword, formVar.startDate, formVar.endDate));
}
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchlead(formVar.limit, formVar.offset, e.target.value, formVar.keyword, formVar.startDate, formVar.endDate))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      brandId: data.id,
      modalTitle: 'Edit Lead',
      name: data.fullName,
      email: data.email,
      mobile: data.phoneNumber,
      altmobile: data.altPhoneNumber,
      enquiryfor: data.enquiryFor,
      query: data.desc,
      statusfor: data.status
    }))
  }
  const EditLeadToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({ ...prevFormVar, modalTitle: 'Edit Lead', viewState: false }))
  }

  const ViewLeadModal = (data, i) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      brandId: data.id,
      name: data.fullName,
      email: data.email,
      mobile: data.phoneNumber,
      altmobile: data.altPhoneNumber,
      enquiryfor: data.enquiryFor,
      query: data.desc,
      statusfor: data.status,
      viewState: true,
      leadIndex: i + 1,
      dateRegistered: moment(data.createdAt).format('DD-MM-YYYY'),
      staffName: data?.staffEnquiry[0]?.staff?.staffName,
      enquiryDoc: data.enquiryDoc,
    }))
  }

  const apiData = storeVar?.leadData;
  const convertToCSV = (data) => {
    if (!data.length) return '';
    const header = ['Serial Number', 'FullName', 'Email', 'PhoneNumber', 'AltPhoneNumber', 'EnquiryFor', 'Desc', 'Date', 'Time', 'Status'];
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const rows = data.map((row, index) => [
      index + 1,
      row.fullName,
      row.email,
      row.phoneNumber,
      row.altPhoneNumber,
      row.enquiryFor,
      row.desc,
      formatDate(row.createdAt),
      row.status
    ]);
    const csvContent = [header.join(','), ...rows.map(row => row.join(','))].join('\n');

    return csvContent;
  };
  const ExportCSV = () => {
    if (apiData) {
      const csvContent = convertToCSV(formVar.idArray.length > 0 ? formVar.idArray : storeVar?.leadData);

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'exported_data.csv';
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Leads',
      name: '',
      email: '',
      mobile: '',
      altmobile: '',
      enquiryfor: '',
      query: '',
    }))
    setSubmit(false)
    // setBrandsName('')
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const handlestatusTypeChange
    = (e) => {
      setFormVar((prevFormVar) => ({ ...prevFormVar, statusfor: e.target.value }))
    };
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      brandId: data.id,
    }))
  }
  const handleSelect = (e) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      asignStaffId: e[0]?.id
    }))
  }
  const openAsignModal = () => {
    if (formVar.idArray?.length > 0) {
      dispatch(isOpenAsignModal(true))
      dispatch(getStaffList(formVar.limit, formVar.offset, 'ACTIVE', formVar.roles, formVar.keyword))
    } else {
      errorHandler({ status: 300, data: { message: 'Please Select Leads' } })
    }
  }
  const submitAsignStaff = () => {
    if (formVar.idArray?.length > 0) {
      if (staffAsignValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(asignStaff({ staffId: formVar.asignStaffId, enquiryId: formVar.idArray }))
    } else {
      errorHandler({ status: 300, data: { message: 'Please Select Leads' } })
    }

  }

  const submitBrands = () => {
    if (NamesValid() || mobileValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updatelead({ id: formVar.brandId, fullName: formVar.name, email: formVar.email, phoneNumber: formVar.mobile, altPhoneNumber: formVar.altmobile, enquiryFor: formVar.enquiryfor, desc: formVar.query }))
    } else {
      dispatch(addlead({ pageId: 10, fullName: formVar.name, email: formVar.email, phoneNumber: formVar.mobile, altPhoneNumber: formVar.altmobile, enquiryFor: formVar.enquiryfor, desc: formVar.query }))
    }
  }
  const submitStatus = () => {

    dispatch(statusUpdateLeadStatus({ id: formVar.brandId, status: stateStatus }))
  }

  const staffAsignValid = () => {
    if (!formVar.asignStaffId) {
      return "Staff is required";
    }
  }

  const NamesValid = () => {
    if (!formVar.name) {
      return "Name is required";
    }
  }
  const mobileValid = () => {
    if (!formVar.mobile) {
      return "Mobile is required";
    }
  }

  const handleDateChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, startDate: e.target.value }));
    dispatch(fetchlead(formVar.limit, formVar.offset, formVar.status, formVar.keyword, e.target.value, formVar.endDate))

  }
  const handleDateendChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, endDate: e.target.value }))
    dispatch(fetchlead(formVar.limit, formVar.offset, formVar.status, formVar.keyword, formVar.startDate, e.target.value))

  }
  function pushData(clientId) {
    formVar.idArray.push(clientId.id);
  }

  const removeId = (arr, cb) => {
    const newArr = []
    arr.forEach(item => {
      if (!cb(item)) {
        newArr.push(item)
      }
    })
    return newArr
  }

  const handleChecked = (isChecked) => {
    if (isChecked) {
      formVar.checkedRows.push(items)
      pushData(items)
    } else {
      // formVar.checkedRows = removeId(formVar.checkedRows, row => row.id === items.id)
      setFormVar((prevFormVar) => ({
        ...prevFormVar, idArray: removeId(formVar.idArray, row => row === items.id),
        checkedRows: removeId(formVar.checkedRows, row => row.id === items.id),
      }))
    }
  }
  function setItem(setItem) {
    setItems(setItem)
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="3">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="2">
                <Input className="form-control form-control-inverse btn-square" max={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                 value={formVar.startDate} onChange={handleDateChange}>
                </Input>
              </Col>
              <Col md="2">
                <Input className="form-control form-control-inverse btn-square" max={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                value={formVar.endDate}  onChange={handleDateendChange}>
                </Input>
              </Col>
              <Col md="2">
                {/* <Nav tabs className="border-tab"> */}
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ALL'>All</option>
                  <option value='NEW'>NEW</option>
                  <option value='COMPLETED'>COMPLETED</option>
                  <option value='UNDER PROCESS'>UNDER PROCESS</option>
                  <option value='REJECTED'>REJECTED</option>
                  <option value='DELETED'>DELETED</option>
                </Input>
                {/* </Nav> */}
              </Col>

              <Col md="3" className='d-flex justify-content-end align-items-center gap-1'>
                <div className="text-end border-2">
                  {/* <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: ExportCSV }}>
                    Export
                  </Btn> */}
                  <div className='cursor-pointer font-info action-icon'>
                    <Download onClick={ExportCSV} />
                    <div className="tooltipCustom">Export to CSV</div>
                  </div>
                </div>
                {localStorage.getItem('roles') === 'ADMIN' && (
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: openAsignModal }}>
                    Asign Lead
                  </Btn>
                </div>
                )}
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Lead
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  {localStorage.getItem('roles') === 'ADMIN' && (<th scope='col'>#</th>)}
                  {/* <Checkbox onChecked={handleChecked} item={'all'} setItem={setItem} disabled={false} /> */}
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Mobile No</th>
                  {/* <th scope='col'>Alternate Mobile</th> */}
                  <th scope='col'>Enquiry For</th>
                  <th scope='col'>Asigned To</th>
                  <th scope='col'>Date of Enquiry</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.leadData?.map((item, index) => (
                  <tr key={item.id}>
                   
                    {localStorage.getItem('roles') === 'ADMIN' && (<Checkbox onChecked={handleChecked} item={item} setItem={setItem} disabled={false} />)}

                    <th scope='row'>{index + 1}</th>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.enquiryFor}</td>
                    <td>{item?.staffEnquiry?.[0]?.staff?.staffName}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString('en-GB')}</td>
                    

                    {/* <td onClick={(e) => openModal(item)}>{item.desc}</td> */}
                    <td>
                      {
                        item.status === 'NEW' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'NEW' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'COMPLETED' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'COMPLETED' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'UNDER PROCESS' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'UNDER PROCESS' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'REJECTED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'REJECTED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer font-success action-icon'>
                          <Eye onClick={(e) => ViewLeadModal(item, index)} />
                          <div className="tooltipCustom">View</div>
                        </div>

                        <div className='cursor-pointer font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>

                        <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div>

                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        <CommonModal isOpen={isModalOpen} title={formVar.modalTitle} toggler={Imagetoggle} >
          <Form>

            <Label className="col-form-label" for="recipient-name">Query</Label>
            {/* <p>{desc}</p> */}

          </Form>
          <ModalFooter>
            <Btn attrBtn={{ color: 'secondary', onClick: closeModal }} >Close</Btn>

          </ModalFooter>
        </CommonModal>

        {
          storeVar.count > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.count} setLimit={setLimit} showLimit={true}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
        
      </Col>

      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Name</Label>
                <Input className="form-control" type="text" placeholder='Enter Name' onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 20)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />

              </Col>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Email</Label>
                <Input className="form-control" type="email" placeholder='Enter Email' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, email: e.target.value }))} value={formVar.email} />
              </Col>

            </Row>
            <Row>
              <Col md='6'>

                {submit && NamesValid() ? <span className='d-block font-danger'>{NamesValid()}</span> : ""}
              </Col>
              <Col md='6'>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Mobile No</Label>
                <Input className="form-control" type="text" placeholder='Enter Mobile No' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, mobile: e.target.value }))} value={formVar.mobile} />
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Alternate Mobile</Label>
                <Input className="form-control" type="text" placeholder='Enter Alternate Mobile No' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, altmobile: e.target.value }))} value={formVar.altmobile} />
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                {submit && mobileValid() ? <span className='d-block font-danger'>{mobileValid()}</span> : ""}
              </Col>
              <Col md='6'>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Label className="col-form-label" for="recipient-name">Enquiry For</Label>
                <Input className="form-control" type="text" placeholder='Enquiry Related For' onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 30)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, enquiryfor: e.target.value }))} value={formVar.enquiryfor} />
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Status</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.statusfor} onChange={handlestatusTypeChange}>
                  <option value='NEW'>NEW</option>
                  <option value='COMPLETED'>COMPLETED</option>
                  <option value='UNDER PROCESS'>UNDER PROCESS</option>
                  <option value='REJECTED'>REJECTED</option>
                  <option value='DELETED'>DELETED</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
              </Col>

            </Row>
            <Label className="col-form-label" for="recipient-name">Query</Label>
            <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, query: e.target.value }))} value={formVar.query} />
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitBrands }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

      <CommonModal isOpen={storeVar.isOpenAsignModal} title={"Asign Staff"} toggler={toggleAsign} >
        <Form>
          <FormGroup>
            <Row>
              <Col md="12">
                <Label className="col-form-label" for="recipient-name">Status</Label>
                <Typeahead
                  id="basic-typeahead"
                  labelKey="name"
                  multiple={multiple}
                  options={staffVar?.staffList}
                  onChange={(e) => handleSelect(e)}
                  placeholder="Choose a staff..."
                />
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggleAsign }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitAsignStaff }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Change Lead Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'NEW'} onChange={onValueChange} value='NEW' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>NEW</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'COMPLETED'} onChange={onValueChange} value='COMPLETED' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>COMPLETED</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'UNDER PROCESS'} onChange={onValueChange} value='UNDER PROCESS' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>UNDER PROCESS</span>
              </Label>
            </div>
          </div>
        </Col>
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline4' type='radio' className="radio_animated" name='radio4' checked={stateStatus === 'REJECTED'} onChange={onValueChange} value='REJECTED' />
              <Label className='mb-0' for='radioinline4'>
                <span className='digits'>REJECTED</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline5' type='radio' className="radio_animated" name='radio5' checked={stateStatus === 'DELETED'} onChange={onValueChange} value='DELETED' />
              <Label className='mb-0' for='radioinline5'>
                <span className='digits'>DELETED</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>



      <CommonModal style={{ alignItems: "flex-start" }} isOpen={formVar.viewState} title={'#' + formVar.leadIndex + ' - ' + formVar.name} toggler={toggleView} size="xl">
        <PillTab leadData={formVar} editBtnAdd={setEditButtonAdd} />
        {editButtonAdd === '1' &&
          <ModalFooter>
            <Btn attrBtn={{ color: 'primary', onClick: EditLeadToggleModal }} >Edit</Btn>
          </ModalFooter>
        }
      </CommonModal>



    </Fragment>
  );
};

export default LeadsTable;
