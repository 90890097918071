import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import FaqsTable from './AboutImage';
const AboutImage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='About Us Images' mainTitle='About Us Images' />
      <Container fluid={true}>
        <Row>
          <FaqsTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default AboutImage;
