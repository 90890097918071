import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { updateaboutUs } from '../../store/aboutUsSlice';
import { useDispatch, useSelector } from 'react-redux';


const HomePageModel = ({ props }) => {
    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [formVar, setFormVar] = useState({
        name1: "",
        name2: "",
        desc1: "",
        desc2: "",
        adHeading1: "",
        adHeading2: "",
        adDesc: "",
    });
    useEffect(() => {
        if (props) {
            setFormVar({
                name1: props?.name1 || '',
                name2: props?.name2 || '',
                desc1: props?.desc1 || '',
                desc2: props?.desc2 || '',
                adHeading1: props?.adHeading1 || '',
                adHeading2: props?.adHeading2 || '',
                adDesc: props?.adDesc || '',

            });
        }
    }, [props]);

    const submitNewSettings = () => {

        if (councelor1NameValid()) {
            setSubmit(true)
            return null
        }
        if (councelor2NameValid()) {
            setSubmit(true)
            return null
        }
        if (councelor1DescValid()) {
            setSubmit(true)
            return null
        }
        if (councelor2DescValid()) {
            setSubmit(true)
            return null
        }
        if (addTitle1Valid()) {
            setSubmit(true)
            return null
        }
        if (addTitle2Valid()) {
            setSubmit(true)
            return null
        }
        if (addDescValid()) {
            setSubmit(true)
            return null
        }
        setSubmit(false)
        dispatch(updateaboutUs(formVar));
    }
    const councelor1NameValid = () => {
        if (!formVar.name1) {
            return "Councelor 1 Name is required";
        }
    }
    const councelor2NameValid = () => {
        if (!formVar.name2) {
            return "Councelor 2 Name is required";
        }
    }
    const councelor1DescValid = () => {
        if (!formVar.desc1) {
            return "Councelor 1 Description is required";
        }
    }
    const councelor2DescValid = () => {
        if (!formVar.desc2) {
            return "Councelor 1 Description is required";
        }
    }
    const addTitle1Valid = () => {
        if (!formVar.adHeading1) {
            return "Add Title 1 is required";
        }
    }
    const addTitle2Valid = () => {
        if (!formVar.adHeading2) {
            return "Add Title 2 is required";
        }
    }
    const addDescValid = () => {
        if (!formVar.adDesc) {
            return "Add Description is required";
        }
    }

    return (
        <>
            <Col sm='12'>
                <Card>
                    <CardHeader>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Councelor 1 </Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Councelor 1 Name</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Councelor 1 Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name1: e.target.value }))} value={formVar?.name1} />
                                    {submit && councelor1NameValid() ? <span className='d-block font-danger'>{councelor1NameValid()}</span> : ""}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col md="">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Councelor 1 Description</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc1: e.target.value }))} value={formVar.desc1} />
                                    {submit && councelor1DescValid() ? <span className='d-block font-danger'>{councelor1DescValid()}</span> : ""}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Councelor 2 </Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Councelor 2 Name</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Councelor 2 Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name2: e.target.value }))} value={formVar?.name2} />
                                    {submit && councelor2NameValid() ? <span className='d-block font-danger'>{councelor2NameValid()}</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col md="">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Councelor 2 Description</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc2: e.target.value }))} value={formVar.desc2} />
                                    {submit && councelor2DescValid() ? <span className='d-block font-danger'>{councelor2DescValid()}</span> : ""}

                                </FormGroup>
                            </Col>
                        </Row>


                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Add Section</Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Add Title 1</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Add Title 1' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adHeading1: e.target.value }))} value={formVar?.adHeading1} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Add Title 2</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Add Title 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adHeading2: e.target.value }))} value={formVar?.adHeading2} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && addTitle1Valid() ? <span className='d-block font-danger'>{addTitle1Valid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && addTitle2Valid() ? <span className='d-block font-danger'>{addTitle2Valid()}</span> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Add Description</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adDesc: e.target.value }))} value={formVar.adDesc} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && addDescValid() ? <span className='d-block font-danger'>{addDescValid()}</span> : ""}
                            </Col>
                        </Row>
                        <ModalFooter style={{ justifyContent: "center" }}>
                            <Btn attrBtn={{ color: 'primary', onClick: submitNewSettings }}>Save </Btn>
                        </ModalFooter>


                    </CardHeader>
                </Card>
            </Col >
        </>
    );
};

export default HomePageModel;
