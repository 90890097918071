import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice';
import loadingReducer from './loader';
import dashboardReducer from './dashboardSlice';
import faqsReducer from './faqsSlice';
import settingsReducer from './settingsSlice';
import universitiesReducer from './universitiesSlice';
import blogsReducer from './blogSlice';
import leadReducer from './leadsSlice';
import countriesReducer from './countriesSlice';
import pageReducer from './pagesSlice';
import degreeReducer from './degreeSlice';
import levelReducer from './levelSlice';
import courseReducer from './courseSlice';
import dataCounterReducer from './dataCounterSlice';
import homePageReducer from './homepageSlice';
import coreValueReducer from './corevaluesSlice';
import aboutusReducer from './aboutUsSlice';
import staffReducer from './staffSlice';
import whyUsReducer from './whyUsSlice';
import testReducer from './testSlice';
import remindersReducer from './remindersSlice';

import scholarshipReducer from './scholarshipSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    leads: leadReducer,
    loader: loadingReducer,
    faqs:faqsReducer,
    settings:settingsReducer,
    universities:universitiesReducer,
    blogs: blogsReducer,
    countries: countriesReducer,
    page: pageReducer,
    degree: degreeReducer,
    level: levelReducer,
    course: courseReducer,
    dataCounter: dataCounterReducer,
    homepage: homePageReducer,
    coreValue: coreValueReducer,
    aboutUs: aboutusReducer,
    staff: staffReducer,
    whyUs: whyUsReducer,
    test: testReducer,
    scholarship: scholarshipReducer,
    reminder: remindersReducer,
  },
})

export default store;