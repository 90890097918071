import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, Image, FileText, BookOpen, Airplay } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { useNavigate } from "react-router-dom";
import {
  fetchTests, isOpenModal, isOpenDetailModal, ModalToggle, isImageOpenModal, ModalDetailsToggle, isOpenStatusModal, statusToggle, addTest, updateTest,
  fetchTestsDetails, setClearState, statusUpdateTestStatus, statusDeletetestStatus,
  updateImageTest,
} from '../../store/testSlice';
import SweetAlert from 'sweetalert2';
import CustomizerContext from '../../_helper/Customizer';
import TestDetailsModel from '../TestsDetails/TestDetails'
import NoImage from '../../assets/images/noimage.png';
import Dropzone from 'react-dropzone-uploader';


const Tests = () => {
  const storeVar = useSelector(state => state.test)
  const dispatch = useDispatch();
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const toggle = () => dispatch(ModalToggle());
  const toggleDetails = () => dispatch(ModalDetailsToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const Imagetoggle = () => dispatch(isImageOpenModal());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    keyword: '',
    testId: '',
    testName: '',
    modalDetailTitle: '',
    bannerFile: null,
    bannerImageURL: null,

  });

  useEffect(() => {
    dispatch(fetchTests(formVar.limit, formVar.offset, formVar.status, formVar.keyword,));
  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(fetchTests(formVar.limit, offset, formVar.status));

  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    dispatch(fetchTests(formVar.limit, formVar.offset, formVar.status, e.target.value))
  }
  const onChangetop = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, desc: evt }))
  };
  const onFocus = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, editerData: evt }))
  }
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeletetestStatus(data.id, 'DELETED'))
        }
      });
  }
  const submitStatus = () => {
    dispatch(statusUpdateTestStatus(formVar.testId, stateStatus))
  }
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchTests(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      testId: data.id,
      testName: data.name,
      metaTag: data.metaTag || '',
      metaTitle: data.metaTitle || '',
      metaKeyword: data.metaKeyword || '',
      metaDescription: data.metaDesc || '',
      modalTitle: 'Edit Test '
    }))

  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Test',
      testName: '',
      metaTag: '',
      metaTitle: '',
      metaKeyword: '',
      metaDescription: '',
    }))
  }



  const EditDetailsToggleModal = (data) => {
    dispatch(setClearState());
    dispatch(fetchTestsDetails(data.id))
    dispatch(isOpenDetailModal(true))

  }




  const onChange = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, amswer: evt }))
  };
  const onChangeScore = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, scoreDesc: evt }))
  };
  const onChangeRegister = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, registerDesc: evt }))
  };
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      testId: data.id,
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }

  const submitTest = () => {

    if (nameValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updateTest(formVar.testId, { name: formVar.testName, metaTag: formVar.metaTag, metaDesc: formVar.metaDescription, metaTitle: formVar.metaTitle, metaKeyword: formVar.metaKeyword }))

    } else {
      dispatch(addTest({ name: formVar.testName, metaTag: formVar.metaTag, metaDesc: formVar.metaDescription, metaTitle: formVar.metaTitle, metaKeyword: formVar.metaKeyword }))
    }
  }
  const nameValid = () => {

    if (!formVar.testName) {
      return "Test Name is required";
    }
  }
  const navigate = (data) => {
    history(`/testsliders/` + layoutURL + '?id=' + data.id)
  }
  const ImageEditBannerModal = (data) => {
    dispatch(isImageOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      id: data.id,
      bannerFile: null,
      bannerImageURL: null,
      modalTitle: 'Update Banner',
    }))
  }
// called every time a file's `status` changes
const handleChangeStatus = ({ meta, file }, status) => {
  if (status === 'done') {
    const reader = new FileReader();
    reader.onload = (e) => {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerImageURL: e.target.result,
      }))
    };
    reader.readAsDataURL(file);
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      bannerFile: file,
    }))
  } else if (status === "removed") {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      bannerFile: null,
      bannerImageURL: null,
    }))
  }
};
const filesValid = () => {
  if (!formVar.bannerFile) {
    return "Files is required";
  }
}

const submitImageTop = () => {
  if (filesValid()) {
    setSubmit(true)
    return null
  }
  setSubmit(false)

  dispatch(updateImageTest(formVar.id, formVar.bannerFile))

}
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>

                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Test
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Banner</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.testData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>
                    <td className='w-10-r'>
                      {item.examDetail[0].banner ? <img className='w-10-r h-5-r' src={item.examDetail[0].banner} alt="" /> : <img className='w-2-r h-2-r' src={NoImage} alt="" />}
                    </td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer  font-success action-icon'>
                          <BookOpen onClick={(e) => EditDetailsToggleModal(item)} />
                          <div className="tooltipCustom">Edit Details</div>
                        </div>
                        <div className='cursor-pointer  font-secondary action-icon'>
                          <Airplay onClick={(e) => navigate(item)} />
                          <div className="tooltipCustom">Sliders</div>
                        </div>
                        <div className='cursor-pointer font-warning action-icon'>
                          <Image onClick={(e) => ImageEditBannerModal(item)} />
                          <div className="tooltipCustom">Update Banner</div>
                        </div>
                        <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Name</Label>
            <Input className="form-control" type="text" placeholder='Enter Test Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, testName: e.target.value }))} value={formVar.testName} />
            {submit && nameValid() ? <span className='d-block font-danger'>{nameValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitTest }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isOpenDetailModal} title={formVar.modalDetailTitle} toggler={toggleDetails} >
        <TestDetailsModel props={storeVar.testDetailsData} />
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal || null} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isImageOpenModal} title={formVar.modalTitle} toggler={Imagetoggle} >
        <Form>
          <FormGroup>
            {
              formVar.bannerImageURL && <>
                <div className='d-flex justify-content-center h-10-r'>
                  <img className=' h-100' src={formVar.bannerImageURL} alt="" />
                </div>
              </>
            }
            <Label className="col-form-label" for="recipient-name">Update Banner</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>

        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: Imagetoggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImageTop }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default Tests;
