import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { updatehomePage } from '../../store/homepageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import CKEditors from 'react-ckeditor-component';


const HomePageModel = ({ props }) => {
    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [formVar, setFormVar] = useState({
        title: '',
        heading: '',
        titleAbout: '',
        subTitleAbout: '',
        bgTitleAbout: '',
        subHeadingAbout1: '',
        subHeadingAbout2: '',
        subHeadingAbout3: '',
        subDescAbout1: '',
        subDescAbout2: '',
        subDescAbout3: '',
        faqTitle: '',
        faqHeading: '',
        courseTitle: '',
        courseHeading: '',
        adHeading1: '',
        adHeading2: '',
        adDesc: '',
        editorData: '',
    });
    useEffect(() => {
        if (props) {
            setFormVar({
                title: props?.title || '',
                heading: props?.heading || '',
                titleAbout: props?.titleAbout || '',
                subTitleAbout: props?.subTitleAbout || '',
                bgTitleAbout: props?.bgTitleAbout || '',
                subHeadingAbout1: props?.subHeadingAbout1 || '',
                subHeadingAbout2: props?.subHeadingAbout2 || '',
                subHeadingAbout3: props?.subHeadingAbout3 || '',
                subDescAbout1: props?.subDescAbout1 || '',
                subDescAbout2: props?.subDescAbout2 || '',
                subDescAbout3: props?.subDescAbout3 || '',
                faqTitle: props?.faqTitle || '',
                faqHeading: props?.faqHeading || '',
                courseTitle: props?.courseTitle || '',
                courseHeading: props?.courseHeading || '',
                adHeading1: props?.adHeading1 || '',
                adHeading2: props?.adHeading2 || '',
                courseHeading: props?.courseHeading || '',
                adDesc: props?.adDesc || '',

            });
        }
    }, [props]);

    const onFocus = (editor) => {
        const evt = editor.getData()
        setFormVar((prevFormVar) => ({ ...prevFormVar, editorData: evt }))
    }
    const onChangeDesc1 = (editor) => {
        const evt = editor.getData()
        setFormVar((prevFormVar) => ({ ...prevFormVar, subDescAbout1: evt }))
    };
    const onChangeDesc2 = (editor) => {
        const evt = editor.getData()
        setFormVar((prevFormVar) => ({ ...prevFormVar, subDescAbout2: evt }))
    };
    const onChangeDesc3 = (editor) => {
        const evt = editor.getData()
        setFormVar((prevFormVar) => ({ ...prevFormVar, subDescAbout3: evt }))
    };
    const submitNewSettings = () => {

        if (bannerTitleValid()) {
            setSubmit(true)
            return null
        }

        if (bannerDescValid()) {
            setSubmit(true)
            return null
        }
        if (aboutTitleValid()) {
            setSubmit(true)
            return null
        }
        if (aboutHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (aboutDescValid()) {
            setSubmit(true)
            return null
        }
        if (subHead1Valid()) {
            setSubmit(true)
            return null
        }
        if (subDesc1Valid()) {
            setSubmit(true)
            return null
        }
        if (subHead2Valid()) {
            setSubmit(true)
            return null
        }
        if (subDesc2Valid()) {
            setSubmit(true)
            return null
        }
        if (subHead3Valid()) {
            setSubmit(true)
            return null
        }
        if (subDesc3Valid()) {
            setSubmit(true)
            return null
        }
        if (faqTitleValid()) {
            setSubmit(true)
            return null
        }
        if (faqHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (courseTitleValid()) {
            setSubmit(true)
            return null
        }
        if (courseHeadingValid()) {
            setSubmit(true)
            return null
        }
        if (addTitle1Valid()) {
            setSubmit(true)
            return null
        }
        if (addTitle2Valid()) {
            setSubmit(true)
            return null
        }
        if (addDescValid()) {
            setSubmit(true)
            return null
        }
        setSubmit(false)
        dispatch(updatehomePage(formVar));
    }
    const bannerTitleValid = () => {
        if (!formVar.title) {
            return "Banner Title is required";
        }
    }
    const bannerDescValid = () => {
        if (!formVar.heading) {
            return "Banner Heading is required";
        }
    }
    const aboutTitleValid = () => {
        if (!formVar.titleAbout) {
            return "About Us Title is required";
        }
    }
    const aboutHeadingValid = () => {
        if (!formVar.subTitleAbout) {
            return "About Us Heading is required";
        }
    }
    const aboutDescValid = () => {
        if (!formVar.bgTitleAbout) {
            return "About Us Description is required";
        }
    }
    const subHead1Valid = () => {
        if (!formVar.subHeadingAbout1) {
            return "Sub Heading 1 is required";
        }
    }
    const subDesc1Valid = () => {
        if (!formVar.subDescAbout1) {
            return "Sub Description 1 is required";
        }
    }
    const subHead2Valid = () => {
        if (!formVar.subHeadingAbout2) {
            return "Sub Heading 2 is required";
        }
    }
    const subDesc2Valid = () => {
        if (!formVar.subDescAbout2) {
            return "Sub Description 2 is required";
        }
    }
    const subHead3Valid = () => {
        if (!formVar.subHeadingAbout3) {
            return "Sub Heading 3 is required";
        }
    }
    const subDesc3Valid = () => {
        if (!formVar.subDescAbout3) {
            return "Sub Description 3 is required";
        }
    }
    const faqTitleValid = () => {
        if (!formVar.faqTitle) {
            return "Faq Title is required";
        }
    }
    const faqHeadingValid = () => {
        if (!formVar.faqHeading) {
            return "Faq Heading is required";
        }
    }
    const courseTitleValid = () => {
        if (!formVar.courseTitle) {
            return "Course Title is required";
        }
    }
    const courseHeadingValid = () => {
        if (!formVar.courseHeading) {
            return "Course Heading is required";
        }
    }
    const addTitle1Valid = () => {
        if (!formVar.adHeading1) {
            return "Add Title 1 is required";
        }
    }
    const addTitle2Valid = () => {
        if (!formVar.adHeading2) {
            return "Add Title 2 is required";
        }
    }
    const addDescValid = () => {
        if (!formVar.adDesc) {
            return "Add Description is required";
        }
    }

    return (
        <>
            <Col sm='12'>
                <Card>
                    <CardHeader>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Banner Section</Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Banner Title</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Banner Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar?.title} />
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Banner Heading</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Banner Heading' onChange={(e) => { setFormVar((prevFormVar) => ({ ...prevFormVar, heading: e.target.value })) }} value={formVar?.heading} minLength={10} maxLength={50} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && bannerTitleValid() ? <span className='d-block font-danger'>{bannerTitleValid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && bannerDescValid() ? <span className='d-block font-danger'>{bannerDescValid()}</span> : ""}
                            </Col>
                        </Row>

                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">About Us Section</Label>

                        <Row>
                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> About Us Title</Label>
                                <Input className="form-control" type="text" placeholder='Enter About Us Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, titleAbout: e.target.value }))} value={formVar?.titleAbout} />
                            </Col>
                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> About Us Heading</Label>
                                <Input className="form-control" type="text" placeholder='Enter About Us Heading' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subTitleAbout: e.target.value }))} value={formVar?.subTitleAbout} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && aboutTitleValid() ? <span className='d-block font-danger'>{aboutTitleValid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && aboutHeadingValid() ? <span className='d-block font-danger'>{aboutHeadingValid()}</span> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> About Us Description</Label>
                                <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, bgTitleAbout: e.target.value }))} value={formVar.bgTitleAbout} />
                            </Col>
                            <Row>
                                <Col md="6">
                                    {submit && aboutDescValid() ? <span className='d-block font-danger'>{aboutDescValid()}</span> : ""}
                                </Col>
                            </Row>
                        </Row>
                        <Row >
                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> Sub Heading 1</Label>
                                <Input className="form-control" type="text" placeholder='Enter About Us Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subHeadingAbout1: e.target.value }))} value={formVar?.subHeadingAbout1} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Label className="col-form-label" for="recipient-name"> Sub Description 1</Label>
                                <CardBody>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formVar.subDescAbout1}
                                        onReady={editor => {
                                            onFocus(editor)
                                        }}
                                        onChange={(event, editor) => {
                                            onChangeDesc1(editor);
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                            onFocus(editor)
                                        }}
                                    />
                                </CardBody>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                {submit && subHead1Valid() ? <span className='d-block font-danger'>{subHead1Valid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && subDesc1Valid() ? <span className='d-block font-danger'>{subDesc1Valid()}</span> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> Sub Heading 2</Label>
                                <Input className="form-control" type="text" placeholder='Enter About Us Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subHeadingAbout2: e.target.value }))} value={formVar?.subHeadingAbout2} />
                            </Col>
                            <Col md="6">
                                {/* <Label className="col-form-label" for="recipient-name"> Sub Description 2</Label> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Label className="col-form-label" for="recipient-name"> Sub Description 2</Label>
                                <CardBody>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formVar.subDescAbout2}
                                        onReady={editor => {
                                            onFocus(editor)
                                        }}
                                        onChange={(event, editor) => {
                                            onChangeDesc2(editor);
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                            onFocus(editor)
                                        }}
                                    />
                                </CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && subHead2Valid() ? <span className='d-block font-danger'>{subHead2Valid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && subDesc2Valid() ? <span className='d-block font-danger'>{subDesc2Valid()}</span> : ""}
                            </Col>
                        </Row>
                        <Row>

                            <Col md="6">
                                <Label className="col-form-label" for="recipient-name"> Sub Heading 3</Label>
                                <Input className="form-control" type="text" placeholder='Enter About Us Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, subHeadingAbout3: e.target.value }))} value={formVar?.subHeadingAbout3} />
                            </Col>
                            <Col md="6">
                                {/* <Label className="col-form-label" for="recipient-name"> Sub Description 3</Label> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Label className="col-form-label" for="recipient-name"> Sub Description 3</Label>
                                <CardBody>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formVar.subDescAbout3}
                                        onReady={editor => {
                                            onFocus(editor)
                                        }}
                                        onChange={(event, editor) => {
                                            onChangeDesc3(editor);
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                            onFocus(editor)
                                        }}
                                    />
                                </CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && subHead3Valid() ? <span className='d-block font-danger'>{subHead3Valid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && subDesc3Valid() ? <span className='d-block font-danger'>{subDesc3Valid()}</span> : ""}
                            </Col>
                        </Row>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Faq Section</Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> FAQs Title</Label>
                                    <Input className="form-control" type="text" placeholder='Enter FAQs Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, faqTitle: e.target.value }))} value={formVar?.faqTitle} />
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">FAQs Heading</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, faqHeading: e.target.value }))} value={formVar.faqHeading} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && faqTitleValid() ? <span className='d-block font-danger'>{faqTitleValid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && faqHeadingValid() ? <span className='d-block font-danger'>{faqHeadingValid()}</span> : ""}
                            </Col>
                        </Row>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Popular Course Section</Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Course Title</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Course Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, courseTitle: e.target.value }))} value={formVar?.courseTitle} />
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Course Heading</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, courseHeading: e.target.value }))} value={formVar.courseHeading} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && courseTitleValid() ? <span className='d-block font-danger'>{courseTitleValid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && courseHeadingValid() ? <span className='d-block font-danger'>{courseHeadingValid()}</span> : ""}
                            </Col>
                        </Row>
                        <Label className="col-form-label fw-semibold f-20" for="recipient-name">Add Section</Label>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Add Title 1</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Add Title 1' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adHeading1: e.target.value }))} value={formVar?.adHeading1} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name"> Add Title 2</Label>
                                    <Input className="form-control" type="text" placeholder='Enter Add Title 2' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adHeading2: e.target.value }))} value={formVar?.adHeading2} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && addTitle1Valid() ? <span className='d-block font-danger'>{addTitle1Valid()}</span> : ""}
                            </Col>

                            <Col md="6">
                                {submit && addTitle2Valid() ? <span className='d-block font-danger'>{addTitle2Valid()}</span> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="col-form-label" for="recipient-name">Add Description</Label>
                                    <textarea className='form-control' name='description' rows='3' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adDesc: e.target.value }))} value={formVar.adDesc} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                {submit && addDescValid() ? <span className='d-block font-danger'>{addDescValid()}</span> : ""}
                            </Col>
                        </Row>
                        <ModalFooter style={{ justifyContent: "center" }}>
                            <Btn attrBtn={{ color: 'primary', onClick: submitNewSettings }}>Save </Btn>
                        </ModalFooter>


                    </CardHeader>
                </Card>
            </Col >
        </>
    );
};

export default HomePageModel;
