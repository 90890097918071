
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const homePageSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    sethomePages(state, { payload }) {
        state.homePageData = payload;
      },
  },
});

export const { sethomePages } = homePageSlice.actions;
export default homePageSlice.reducer;

export function fetchhomePages() {
  return async function fetchhomePagesThunk(dispatch, getState) {
    try {
      await service.fetchHomePage().then(
        (response) => {
          dispatch(sethomePages(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function updatehomePage(payload) {
  return async function updateDataThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatehomePagedata(payload).then(
        (response) => {
          dispatch(sethomePages(response.data[0]));
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateHomeBannerImage(file) {
  return async function updateHomeBannerImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateHomeBannerImage(file).then(
        (response) => {
          dispatch(sethomePages(response.data))
          dispatch(setLoading(false))
          successHandler('Image Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}
export function updateHomeAboutUsImage(file) {
  return async function updateHomeAboutUsImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatehomePageAboutUsImage(file).then(
        (response) => {
          dispatch(sethomePages(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}
export function updateHomeFaqImage(id, file) {
  return async function updateHomeFaqImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatehomePageFaqImage(id, file).then(
        (response) => {
          dispatch(sethomePages(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}
export function updateHomeAddImage(id, file) {
  return async function updateHomeAddImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatehomePageAddImage(id, file).then(
        (response) => {
          dispatch(sethomePages(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}

