import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, Image, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchscholarship, isOpenModal, ModalToggle, addScholarship, updateScholarship, isOpenStatusModal, statusToggle, statusUpdateScholarshipStatus, statusDeleteScholarshipStatus } from '../../store/scholarshipSlice';
import { fetchCountries } from '../../store/countriesSlice';
import { fetchdegree } from '../../store/degreeSlice';
import Pagination from '../../Components/Pagination/Pagination';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'sweetalert2';

const Scholarship = () => {
  const storeVar = useSelector(state => state.scholarship)
  console.log(storeVar);
  const countryVar = useSelector(state => state.countries)
  const degreeVar = useSelector(state => state.degree)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [DegreeIds, setDegreeIds] = useState()
  const [CountryIds, setCountryIds] = useState()
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 99,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    name: '',
    scholarshipId: '',
    deadline: '',
    amount: '',
    countryId: [],
    degreeId: [],
  });

  useEffect(() => {
    dispatch(fetchscholarship(formVar.limit, formVar.offset, formVar.status, formVar.keyword,));
    dispatch(fetchCountries('99', '0', 'ACTIVE', ''));
    dispatch(fetchdegree('99', '0', 'ACTIVE', '',));
  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(fetchscholarship(formVar.limit, offset, formVar.status));
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    dispatch(fetchscholarship(formVar.limit, formVar.offset, formVar.status, e.target.value))
  }
  const onChangetop = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, desc: evt }))
  };
  const onFocus = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, editerData: evt }))
  }
  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeleteScholarshipStatus(data.id, 'DELETED'))
        }
      });
  }
  const submitStatus = () => {
    dispatch(statusUpdateScholarshipStatus({ id: formVar.scholarshipId, status: stateStatus }))
  }
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchscholarship(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      name: data.name,
      deadline: data.deadline,
      amount: data.amount,
      scholarshipId: data.id,
      modalTitle: 'Edit Scholarship'
    }))
    if (data && data.scholarshipCountry) {
      formVar.countryId = [];
      data?.scholarshipCountry.forEach(elem => {
        if (elem.country) {
          formVar.countryId.push(elem.country);
        } else {
          setFormVar((prevFormVar) => ({
            ...prevFormVar,
            countryId: []
          }));
        }
      });
    }
    if (data && data.scholarshipDegree) {
      formVar.degreeId = [];
      data?.scholarshipDegree.forEach(elem => {
        if (elem.degree) {
          formVar.degreeId.push(elem.degree);
        } else {
          setFormVar((prevFormVar) => ({
            ...prevFormVar,
            degreeId: []
          }));
        }
      });
    }

  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Scholarship',
      name: '',
      deadline: '',
      amount: '',
      countryId: [],
      degreeId: [],
    }))
    setDegreeIds([]);
    setCountryIds([]);
    setSubmit(false)
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      scholarshipId: data.id,
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }

  const submitScholarship = () => {

    if (titleValid()) {
      setSubmit(true)
      return null
    }
    if (deadlineValid()) {
      setSubmit(true)
      return null
    }
    if (amountValid()) {
      setSubmit(true)
      return null
    }

    const data = {
      "pageId": 9,
      "name": formVar.name,
      "deadline": formVar.deadline,
      "amount": formVar.amount,
      "degree": DegreeIds,
      "country": CountryIds
    }
    if (formVar.editState) {
      setSubmit(false)
      dispatch(updateScholarship(formVar.scholarshipId, data))

    } else {
      if (degreeValid()) {
        setSubmit(true)
        return null
      }
      if (countryValid()) {
        setSubmit(true)
        return null
      }
      setSubmit(false)
      dispatch(addScholarship(data))
    }
  }
  const titleValid = () => {

    if (!formVar.name) {
      return "Scholarship Name is required";
    }
  }
  const deadlineValid = () => {
    if (!formVar.deadline) {
      return "Deadline is required";
    }
  }
  const amountValid = () => {
    if (!formVar.amount) {
      return "Amount is required";
    }
  }
  const degreeValid = () => {
    if (!DegreeIds) {
      return "Degree is required";
    }
  }
  const countryValid = () => {
    if (!CountryIds) {
      return "Country is required";
    }
  }
  const onChange = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, deadline: evt }))
  };
  const onChangeamount = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, amount: evt }))
  };
  const handleSelectDegree = (e) => {
    setDegreeIds(e.map(item => {
      return {
        degreeId: item.id
      };
    }))
  }
  const handleSelectCountry = (e) => {
    setCountryIds(e.map(item => {
      return {
        countryId: item.id
      };
    }))
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>

                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Scholarship
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Country</th>
                  <th scope='col'>Degree</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.scholarshipData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.scholarshipCountry?.map((data, index) => (
                      <span key={index}>{data.country.name}<br /></span>
                    ))}
                    </td>
                    <td>{item.scholarshipDegree?.map((data, index) => (
                      <span key={index}>{data.degree.name}<br /></span>
                    ))}
                    </td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && <CheckCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && <XCircle />}
                            &nbsp; {item.status}
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer action-icon'>
                          <FileText onClick={(e) => statusToggleModal(item)} />
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Scholarship Name</Label>
            <Input className="form-control" type="text" placeholder='Enter Scholarship Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
            {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Degree</Label>
            <Typeahead
              id="multiple-typeahead"
              labelKey={'name'}
              multiple
              clearButton
              options={degreeVar.degreeData}
              defaultSelected={formVar.degreeId}
              onChange={(e) => handleSelectDegree(e)}
              placeholder="Select Degree..."
            />
            {submit && degreeValid() ? <span className='d-block font-danger'>{degreeValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Deadline</Label>
            <CardBody>
              <CKEditor
                editor={ClassicEditor}
                data={formVar.deadline}
                onReady={editor => {
                  onFocus(editor)
                }}
                onChange={(event, editor) => {
                  onChange(editor);
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                  onFocus(editor)
                }}
              />
            </CardBody>
            {submit && deadlineValid() ? <span className='d-block font-danger'>{deadlineValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Amount</Label>
            <CardBody>
              <CKEditor
                editor={ClassicEditor}
                data={formVar.amount}
                onReady={editor => {
                  onFocus(editor)
                }}
                onChange={(event, editor) => {
                  onChangeamount(editor);
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                  onFocus(editor)
                }}
              />
            </CardBody>            {submit && amountValid() ? <span className='d-block font-danger'>{amountValid()}</span> : ""}


            <Label className="col-form-label" for="recipient-name">Country</Label>
            <Typeahead
              id="multiple-typeahead"
              labelKey={'name'}
              multiple
              clearButton
              options={countryVar.countriesData}
              defaultSelected={formVar.countryId}
              onChange={(e) => handleSelectCountry(e)}
              placeholder="Select Country..."
            />

            {submit && countryValid() ? <span className='d-block font-danger'>{countryValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitScholarship }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default Scholarship;
