import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  coreValueData: [],
  coreValuepecializationData: [],
  isOpenModal: false,
  isStatusOpenModal: false,
  totalFaq: 0,
}

export const coreValueSlice = createSlice({
  name: "coreValue",
  initialState,
  reducers: {
    setcoreValueData(state, { payload }) {
      state.coreValueData = payload.result
      state.totalFaq = payload.total
    },
    updatecoreValueData(state, { payload }) {
      const objIndex = state.coreValueData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.coreValueData[objIndex] = payload
      }
    },
    pushcoreValue(state, { payload }) {
      state.coreValueData.push(payload)
    },
    setcoreValueSpecializationData(state, { payload }) {
      state.coreValuepecializationData = payload.result
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setcoreValueData, updatecoreValueData, pushcoreValue, isOpenModal, ModalToggle, setcoreValueSpecializationData } = coreValueSlice.actions;

export default coreValueSlice.reducer;

export function getcoreValue(limit, offset, status, keyword) {
  return async function getcoreValueThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.CoreValuesData().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setcoreValueData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updatecoreValue(id, question, answer) {
  return async function updatecoreValueThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecoreValueData(id, question, answer).then(
        (response) => {
          dispatch(updatecoreValueData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          // errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
