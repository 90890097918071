export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    menucontent: "Dashboards",
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard/social`, show: true, icon: "home", title: "Dashboard", type: "link" },
      {
        title: "Staff Management",
        icon: "setting",
        type: "sub",
        menutitle: "Staff",
        menucontent: "Staffs, Stafflist, Staffadd",
        show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/staff-list`, icon: "faqs", type: "link", active: false, title: "Staff-List" },
          // { path: `${process.env.PUBLIC_URL}/dashboard/social`, icon: "faqs", type: "link", active: false, title: "Staff Permissions" },
        ]
      },
    ],
  },
  {
    menutitle: "Leads",
    show: true,
    menucontent: "Universities, Countries, Courses",
    Items: [
      { path: `${process.env.PUBLIC_URL}/leads`, icon: "degree", show: true, title: "Leads", type: "link" },

    ],
  },
  {
    menutitle: "General",
    show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
    menucontent: "Universities, Countries, Courses",
    Items: [
      // { path: `${process.env.PUBLIC_URL}/leads`, icon: "degree", show: true, title: "Leads", type: "link" },
      { path: `${process.env.PUBLIC_URL}/countries`, icon: "degree", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, title: "Countries", type: "link" },
      { path: `${process.env.PUBLIC_URL}/universities`, icon: "universities", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Universities" },
      { path: `${process.env.PUBLIC_URL}/course`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Course" },
      { path: `${process.env.PUBLIC_URL}/degree`, icon: "degree", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Degree" },
      { path: `${process.env.PUBLIC_URL}/level`, icon: "level", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Level" },
      { path: `${process.env.PUBLIC_URL}/tests`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Tests" },
      { path: `${process.env.PUBLIC_URL}/scholarship`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Scholarship" },
    ],
  },

  {
    menutitle: "Home Page",
    show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
    menucontent: "HomePage",
    Items: [
      {
        title: "Home Page",
        icon: "form",
        type: "sub",
        menutitle: "Home Page",
        menucontent: "Home Page",
        show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/homepage`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Homepage" },
          { path: `${process.env.PUBLIC_URL}/datacounter`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "DataCounter" },
          { path: `${process.env.PUBLIC_URL}/homeimages`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "HomeImages" },
        ]
      },
    ],
  },
  {
    menutitle: "About Us",
    menucontent: "HomePage",
    show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
    Items: [
      {
        title: "About Us",
        icon: "form",
        type: "sub",
        menutitle: "About Us",
        menucontent: "About Us",
        show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/aboutus`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "AboutUs" },
          { path: `${process.env.PUBLIC_URL}/aboutimages`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "AboutImages" },
          { path: `${process.env.PUBLIC_URL}/corevalues`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "CoreValues" },
        ]
      },
    ],
  },
  {
    menutitle: "Why Us",
    menucontent: "HomePage",
    show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
    Items: [
      {
        title: "Why Us",
        icon: "form",
        type: "sub",
        menutitle: "About Us",
        menucontent: "About Us",
        show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/whyus`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "WhyUS" },
          { path: `${process.env.PUBLIC_URL}/perks`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Perks" },
        ]
      },
    ]
  },



  {
    menutitle: "Settings",
    menucontent: "Bouns Pages",
    show: localStorage.getItem('roles') === 'ADMIN' ? true : false,
    Items: [
      // { path: `/banner`, icon: "banner", type: "link", active: false, title: "Banner" },
      // { path: `/slider`, icon: "slider", type: "link", active: false, title: "Slider" },
      { path: `${process.env.PUBLIC_URL}/pages`, icon: "form", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", title: "Pages" },
      { path: `${process.env.PUBLIC_URL}/blogs`, icon: "degree", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", title: "Blogs" },
      { path: `${process.env.PUBLIC_URL}/faqs`, icon: "faqs", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "FAQ" },
      { path: `${process.env.PUBLIC_URL}/settings`, icon: "settings", show: localStorage.getItem('roles') === 'ADMIN' ? true : false, type: "link", active: false, title: "Settings" },
    ],
  },




];
