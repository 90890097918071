// dashbaord
import Social from "../Components/Dashboard/Social";



import Leads from "../Pages/Leads";
import Settings from "../Pages/Settings";
import Faqs from "../Pages/Faqs";
import Staff from "../Pages/Staff";
import Universities from "../Pages/Universities";

import Blogs from "../Pages/Blogs"
import Countries from "../Pages/Countries"
import Page from "../Pages/Page";
import PageEdit from "../Pages/PageEdit";
import Degree from "../Pages/Degree";
import Level from "../Pages/Level";
import Course from "../Pages/Course";
import Homepage from "../Pages/HomePage";
import DataCounter from "../Pages/DataCounter";
import HomeImages from "../Pages/HomeImage";
import CoreValues from "../Pages/CoreValues";
import AboutImage from "../Pages/AboutImage"
import AboutUs from "../Pages/AboutUs";
import WhyUs from "../Pages/WhyUs";
import Perks from "../Pages/Perks";
import Tests from "../Pages/Tests";
import TestSliders from "../Pages/TestSliders";
import TestDetails from "../Pages/TestsDetails";
import DocRequired from "../Pages/DocRequired";
import Scholarship from "../Pages/Scholarship";


export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/social/:layout`, Component: <Social /> },

  { path: `${process.env.PUBLIC_URL}/leads/:layout`, Component: <Leads /> },
  { path: `${process.env.PUBLIC_URL}/blogs/:layout`, Component: <Blogs /> },
  { path: `${process.env.PUBLIC_URL}/countries/:layout`, Component: <Countries /> },
  { path: `${process.env.PUBLIC_URL}/degree/:layout`, Component: <Degree /> },
  { path: `${process.env.PUBLIC_URL}/level/:layout`, Component: <Level /> },
  { path: `${process.env.PUBLIC_URL}/course/:layout`, Component: <Course /> },
  { path: `${process.env.PUBLIC_URL}/homepage/:layout`, Component: <Homepage /> },
  { path: `${process.env.PUBLIC_URL}/datacounter/:layout`, Component: <DataCounter /> },
  { path: `${process.env.PUBLIC_URL}/homeimages/:layout`, Component: <HomeImages /> },
  { path: `${process.env.PUBLIC_URL}/aboutus/:layout`, Component: <AboutUs /> },
  { path: `${process.env.PUBLIC_URL}/aboutimages/:layout`, Component: <AboutImage /> },
  { path: `${process.env.PUBLIC_URL}/corevalues/:layout`, Component: <CoreValues /> },
  { path: `${process.env.PUBLIC_URL}/whyus/:layout`, Component: <WhyUs /> },
  { path: `${process.env.PUBLIC_URL}/perks/:layout`, Component: <Perks /> },
  { path: `${process.env.PUBLIC_URL}/tests/:layout`, Component: <Tests /> },
  { path: `${process.env.PUBLIC_URL}/testsliders/:layout`, Component: <TestSliders /> },
  { path: `${process.env.PUBLIC_URL}/testdetails/:layout`, Component: <TestDetails /> },
  { path: `${process.env.PUBLIC_URL}/docrequired/:layout`, Component: <DocRequired /> },
  { path: `${process.env.PUBLIC_URL}/scholarship/:layout`, Component: <Scholarship /> },


  // Staff
  { path: `/staff-list/:layout`, Component: <Staff /> },

  // Faq
  { path: `/faqs/:layout`, Component: <Faqs /> },

  // University
  { path: `/universities/:layout`, Component: <Universities /> },

  { path: `${process.env.PUBLIC_URL}/pages/:layout`, Component: <Page /> },
  { path: `${process.env.PUBLIC_URL}/edit-page/:layout`, Component: <PageEdit /> },
  { path: `${process.env.PUBLIC_URL}/settings/:layout`, Component: <Settings /> },


  //Error
  // { path: `*`, Component: <ErrorPage4 /> },

];
