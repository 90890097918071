import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, Image, HardDrive, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import { getSettings, ModalToggle, isOpenModal, statusToggle, imageToggle, isOpenImageModal, isOpenStatusModal, addSettingsLogo, addSettingsFavicon, statusUpdateSettings, updateNewSettingData } from '../../store/settingsSlice';
import NoImage from '../../assets/images/noimage.png';
import SettingsTableModel from './SettingsTableModel';

const SettingsTable = () => {
  const storeVar = useSelector(state => state.settings)
  const dispatch = useDispatch();
  
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const imageModalToggle = () => dispatch(imageToggle());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    settingIdURL: null,
    settingFile: null,
    settingIdImage: null,
    imageStatus: null,
  });

  useEffect(() => {
    dispatch(getSettings());
  }, []);


  const imageToggleModal = (type) => {
    dispatch(isOpenImageModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      imageStatus: type,
    }))
  }
  const submitImage = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.imageStatus === 1) {
      dispatch(addSettingsLogo({ file: formVar.settingFile }))
    } else if (formVar.imageStatus === 2) {
      dispatch(addSettingsFavicon({ file: formVar.settingFile }))
    }
  }
  const handleChangeFileStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          settingIdURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        settingFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        settingFile: null,
        settingIdURL: null,
      }))
    }
  };


  const filesValid = () => {
    if (!formVar.settingFile) {
      return "Files is required";
    }
  }

  return (
    <Fragment>
      <SettingsTableModel props={storeVar.settingAllData}/>


      <div className='table-responsive'>
        <Card>
          <Table hover={true} className='table-border-horizontal table-light'>
            <thead>
              <tr>
                <th scope='col' className='w-25'>Title</th>
                <th scope='col'>Image</th>
                <th scope='col'>Action</th>
                <th scope='col'>Image Dimension</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Site Logo</td>
                <td className='w-25'>
                  {storeVar?.settingAllData?.logo ? <img className='w-100 h-5-r' src={storeVar?.settingAllData?.logo} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
                </td>

                <td>
                  <div className='d-flex gap-2'>
                    <div className='cursor-pointer font-success action-icon'>
                      <Image onClick={() => imageToggleModal(1)} />
                      <div className="tooltipCustom">Change Logo</div>
                    </div>
                  </div>
                </td>
                <td>Site Logo (Recomended Size is 130px X 50px)</td>

              </tr>
              <tr>
                <td>Site Favicon</td>
                <td className='w-25'>
                  {storeVar?.settingAllData?.favIcon ? <img className='w-auto h-5-r' src={storeVar?.settingAllData?.favIcon} alt="" /> : <img className='w-75 h-5-r' src={NoImage} alt="" />}
                </td>

                <td>
                  <div className='d-flex gap-2'>
                    <div className='cursor-pointer font-success action-icon'>
                      <Image onClick={() => imageToggleModal(2)} />
                      <div className="tooltipCustom">Change Favicon</div>
                    </div>
                  </div>
                </td>
                <td>Site Fab Icon (Recomended Size is 30px X 30px)</td>

              </tr>
            </tbody>
          </Table>
        </Card>
      </div>


      <CommonModal isOpen={storeVar.isImageOpenModal} title={'Image Upload'} toggler={imageModalToggle} >
        <Form>
          {
            formVar.settingIdURL || formVar.settingIdImage ?
              <>
                <div className='d-flex justify-content-center h-10-r bgLightBlack'>
                  <img className=' h-100' src={formVar.settingIdURL ? formVar.settingIdURL : formVar.settingIdImage} alt="" />
                </div>
              </>
              : ""
          }

          <FormGroup>
            <Label className="col-form-label" for="recipient-name">{formVar.imageStatus === 1 ? "Upload Logo" : "Upload Favicon"}</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeFileStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: imageModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImage }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default SettingsTable;
