import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: "",
  reminderOneData: {},
  reminderDesc: '',
  reminderTitle:'',
  reminderData: [],
  activityLogData:[],
}

export const reminderSlice = createSlice({
  name: "reminder",
  initialState,
  reducers: {
    setOnereminderData(state, { payload }) {
      state.reminderOneData = payload
      state.reminderDesc = payload.desc
      state.reminderTitle = payload.title
    },
    setreminderData(state, { payload }) {
      state.reminderData = payload.result
    },
    setAddReminderData(state,{payload}){
      let data = {
        desc: payload[0]?.desc,
        id: payload[0]?.id,
        remindDateTime: payload[0]?.remindDateTime,
        staff: {
          id: payload[0]?.staffId, staffName: payload[0]?.staffName
        }
      }
      state.reminderData.push(data)
    },
    setUpdateReminderData(state,{payload}){
      let data = {
        desc: payload.desc,
        id: payload.id,
        remindDateTime: payload.remindDateTime,
        staff: {
          id: payload.staffId, staffName: payload.staffName
        }
      }
      const objIndex = state.reminderData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.reminderData[objIndex] = data
      }
    },
    setDeleteeminderData(state,{payload}){
      const objIndex = state.reminderData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.reminderData.splice(objIndex,1)
      }
    },
    setActivityLog(state,{payload}){
      state.activityLogData=payload
    },
  }
})


export const { setOnereminderData, setreminderData,setAddReminderData,setUpdateReminderData,setDeleteeminderData,
  setActivityLog, } = reminderSlice.actions;
export default reminderSlice.reducer;


export function getreminder(limit,offset,enquiryId) {
  return async function getreminderThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.getreminder(limit,offset,enquiryId).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(setreminderData(response.data))
          }
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function addReminders(payload,staffName) {
  return async function addReminderThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.asignStaff(payload).then(
        (response) => {
          if (response.data) {
            response.data[0].staffName=staffName
            dispatch(setLoading(false))
            dispatch(setAddReminderData(response.data))
            successHandler('Added Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateReminder(payload,staffName) {
  return async function updatereminderThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateReminder(payload,payload.id).then(
        (response) => {
          if (response.data) {
            response.data.staffName=staffName
            dispatch(setLoading(false))
            dispatch(setUpdateReminderData(response.data))
            successHandler('Updated Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteReminder(id) {
  return async function deletereminderThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteReminder(id).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(setDeleteeminderData({id}))
            successHandler('Delete Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getActivityLog(id) {
  return async function getActivityLogThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.getActivityLog(id).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(setActivityLog(response.data))
          }
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}