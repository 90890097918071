import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import FaqsTable from './DocRequired';
const DataCounter = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Document Required' mainTitle='Document Required' />
      <Container fluid={true}>
        <Row>
          <FaqsTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DataCounter;
