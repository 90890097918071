
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const DataCounterSlice = createSlice({
  name: 'DataCounter',
  initialState,
  reducers: {
    setDataCounters(state, { payload }) {
        state.DataCounterData = payload;
      },
    updateCounterData(state, { payload }) {
      const objIndex = state.DataCounterData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.DataCounterData[objIndex] = payload
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setDataCounters, updateCounterData, DeleteBrandsData, isOpenModal, isImageOpenModal, ModalToggle, setFaqsSpecializationData, isOpenStatusModal, statusToggle, ImagestatusToggle } = DataCounterSlice.actions;
export default DataCounterSlice.reducer;

export function fetchDataCounters(status) {
  return async function fetchDataCountersThunk(dispatch, getState) {
    try {
      await service.fetchDataCounter(status).then(
        (response) => {
          dispatch(setDataCounters(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function updateDataCounter(id, title, count) {
  return async function updateDataThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateDataCounterdata(id, title, count).then(
        (response) => {
          dispatch(updateCounterData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageDataCounter(id, file) {

  return async function updateCounterImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateDataCounterImage(id, file).then(
        (response) => {
          dispatch(updateCounterData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


